import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from './services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiVersionService } from './services/apiVersion.service';
import { Globals } from './config/globals';
import { Platform } from '@ionic/angular';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ConsumerService } from './services/consumer.service';
import { StorageService } from './services/ionic-storage.service';
import { TablesService } from './services/tables.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

    public showUpdateModal = false;
    public linkAndroid: string;
    public linkIos: string;
    public updateHeading: string;
    public updateText: string;

    constructor(
        public apiVersionService: ApiVersionService,
        private firebase: FirebaseX,
        private translate: TranslateService,
        private languageService: LanguageService,
        private storageService: StorageService,
        private consumerService: ConsumerService,
        private tablesService: TablesService,
        private router: Router,
        private platform: Platform,
        private messageService: MessageService,
        private ngZone: NgZone,
    ) {
    }

    ngOnInit() {
        // check api version if we need to update
        this.linkAndroid = Globals.config.APP_LINKS.ANDROID;
        this.linkIos = Globals.config.APP_LINKS.IOS;
        this.apiVersionService.getApiVersion().then(data => {
            const serverVersionHigher = this.apiVersionService.checkApiVersion(data.data.apiVersion);
            this.showUpdateModal = this.platform.is('cordova') && serverVersionHigher;
            console.log('showUpdateModal', this.showUpdateModal);
            this.updateHeading = this.translate.instant('general.update-heading');
            this.updateText = this.translate.instant('general.update-text');
            this.init();
        });
        this.consumerService.getCountries().then(resp => {
            localStorage.setItem('countries', JSON.stringify(resp.data.countries));
        });
    }

    init() {
        // setup push
        if (this.platform.is('cordova')) {
            console.log('app.component platform', this.platform.is('cordova'), this.platform.is('hybrid'));
            this.platform.ready().then(() => {
                this.firebase.grantPermission().then();
                this.firebase.getToken().then(token => {
                    this.consumerService.saveFcmTokenToStorage(token);
                    console.log(`The token:`, token);
                });
                this.firebase.onMessageReceived().subscribe(async (data) => {
                    const userId = await this.storageService.get('consumerId');
                    console.log(`FCM message received, consumerId`, data, userId);
                    this.messageService.clear();
                    if (data.notification_type) {
                        if (data.notification_type === 'claim-available') {
                            const message = userId ? data?.notification_message : this.translate.instant('push.push-login-first');
                            if (!userId) {
                                this.messageService.add({
                                    severity: 'info',
                                    summary: this.translate.instant('push.new-booking'),
                                    detail: message ?? '',
                                    styleClass: 'push-message'
                                });
                            } else {
                                this.tablesService.restaurantIdFromPush.next(data.restaurant_id);
                                this.messageService.add({
                                    severity: 'info',
                                    summary: data?.notification_title ?? this.translate.instant('push.new-booking'),
                                    detail: message ?? '',
                                    styleClass: 'push-message'
                                });
                                this.gotoMyTables();
                            }
                        } else {
                            this.messageService.add({
                                severity: 'info',
                                summary: data?.notification_title ?? this.translate.instant('push.new-message'),
                                detail: data?.notification_message ?? '',
                                styleClass: 'push-message'
                            });
                        }
                    }
                });
            });
        }
    }

    private gotoMyTables() {
        this.ngZone.run(() => {
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['/my-tables']);
        });
    }
}
