import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-m-card-question-phone',
    templateUrl: './m-card-question-phone.component.html',
    styleUrls: ['./m-card-question-phone.component.scss'],
})
export class MCardQuestionPhoneComponent implements OnInit {

    @Input()
    public establishmentName = '';
    @Input()
    public phoneNumber = '';

    constructor(public translate: TranslateService) {
    }

    ngOnInit() {
    }

}
