import { Injectable } from '@angular/core';
import {
    ConsumerInput,
    RegisterConsumerInput,
    RegisterConsumerMutationService,
    UpdateConsumerMutationService,
    DeleteConsumerMutationService, CountriesQueryService
} from '../generated/graphql';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './ionic-storage.service';

@Injectable({
    providedIn: 'root'
})
export class ConsumerService {

    public state = {
        currentConsumerInfo: new BehaviorSubject(
            {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                language: '',
                country: ''
            }
        ),
        consumerId: new BehaviorSubject(''),
        token: new BehaviorSubject(''),
        fcmToken: new BehaviorSubject(''),
    };

    constructor(
        private countryQueryService: CountriesQueryService,
        private registerConsumerMutationService: RegisterConsumerMutationService,
        private updateConsumerMutationService: UpdateConsumerMutationService,
        private deleteConsumerMutationService: DeleteConsumerMutationService,
        private storageService: StorageService,
    ) {
        this.init().then();
    }

    public getCountries() {
        return this.countryQueryService.fetch(null, {
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.getValue()}`
                }
            }
        }).toPromise();
    }

    public updateConsumer(input: ConsumerInput, id: string) {
        return this.updateConsumerMutationService.mutate({input, id}, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.state.token.value}`
                },
            }
        }).toPromise().then((response) => {
            this.storageService.set('currentConsumerInfo', input);
            this.state.currentConsumerInfo.next({
                first_name: input.first_name,
                last_name: input.last_name,
                phone: input.phone,
                email: input.email,
                language: localStorage.getItem('language') || 'nl',
                country: input.country.connect
            });
        });
    }

    public registerConsumer(input: RegisterConsumerInput) {
        return this.registerConsumerMutationService.mutate({input}).toPromise().then((response) => {
            const consumer = response.data.registerConsumer;
            this.storageService.set('currentConsumerInfo', input);
            this.state.currentConsumerInfo.next({
                first_name: input.first_name,
                last_name: input.last_name,
                phone: input.phone,
                email: input.email,
                language: localStorage.getItem('language') || 'nl',
                country: input.country.connect,
            });
            this.storageService.set('consumerId', consumer.user.id);
            localStorage.setItem('consumerId', consumer.user.id);
            this.state.consumerId.next(consumer.user.id);
            this.storageService.set('token', consumer.token);
            this.state.token.next(consumer.token);
        });
    }

    public deleteConsumer(id: string) {
        localStorage.setItem('consumerId', '');
        return this.deleteConsumerMutationService.mutate({id}).toPromise();
    }

    public saveFcmTokenToStorage(fcmToken: string) {
        this.state.fcmToken.next(fcmToken);
        localStorage.setItem('fcmToken', fcmToken);
        if (fcmToken === '') {
            return this.storageService.remove('fcmToken');
        } else {
            this.storageService.set('fcmToken', fcmToken);
        }
    }

    public resetState() {
        this.state = {
            currentConsumerInfo: new BehaviorSubject(
                {
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    language: '',
                    country: ''
                }
            ),
            consumerId: new BehaviorSubject(''),
            token: new BehaviorSubject(''),
            fcmToken: new BehaviorSubject(''),
        };
        localStorage.clear();
        this.storageService.clear();
    }

    private async init() {
        this.storageService.get('currentConsumerInfo').then((result) => {
            if (result) {
                this.state.currentConsumerInfo.next(result);
            }
        });
        this.storageService.get('consumerId').then((result) => {
            if (result) {
                localStorage.setItem('consumerId', result);
                this.state.consumerId.next(result);
            }
        });
        this.storageService.get('token').then((result) => {
            if (result) {
                this.state.token.next(result);
            }
        });
        this.storageService.get('fcmToken').then((result) => {
            if (result) {
                this.state.fcmToken.next(result);
            }
        });
    }
}
