import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/initial-filter/initial-filter.module').then(m => m.InitialFilterPageModule)
    },
    {
        path: 'overview',
        loadChildren: () => import('./pages/overview-list/overview-list.module').then(m => m.OverviewListPageModule)
    },
    {
        path: 'overview-map',
        loadChildren: () => import('./pages/overview-map/overview-map.module').then(m => m.OverviewMapPageModule)
    },
    {
        path: 'my-tables',
        loadChildren: () => import('./pages/my-tables/my-tables.module').then(m => m.MyTablesModule)
    },
    {
        path: 'my-profile',
        loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule)
    },
    {
        path: 'establishment/:id',
        loadChildren: () => import('./pages/establishment-detail/establishment-detail.module').then(m => m.EstablishmentDetailPageModule)
    },
    {
        path: 'modals',
        loadChildren: () => import('./pages/modals/modals.module').then(m => m.ModalsPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
