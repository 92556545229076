import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { ATextHeadingDirective } from '../../components/ftable/atoms/a-text-heading.directive';
import { AButtonDirective } from '../../components/ftable/atoms/a-button.directive';
import { ALabelDirective } from '../../components/ftable/atoms/a-label.directive';
import { AInputDirective } from '../../components/ftable/atoms/a-input.directive';
import { AParagraphDirective } from '../../components/ftable/atoms/a-paragraph.directive';
import { MCardEstablishmentItemComponent }
    from '../../components/ftable/molecules/m-card-establishment-item/m-card-establishment-item.component';
import { MCardInitialFilterComponent }
    from '../../components/ftable/molecules/m-card-initial-filter/m-card-initial-filter.component';
import { MHeaderFilterComponent }
    from '../../components/ftable/molecules/m-header-filter/m-header-filter.component';
import { CPopupComponent } from '../../components/ftable/containers/c-popup/c-popup.component';
import { MRestaurantInfoComponent }
    from '../../components/ftable/molecules/m-restaurant-info/m-restaurant-info.component';
import { MBlockLightboxComponent }
    from '../../components/ftable/molecules/m-block-lightbox/m-block-lightbox.component';
import { MCardQuestionPhoneComponent }
    from '../../components/ftable/molecules/m-card-question-phone/m-card-question-phone.component';
import { OCardEstablishmentItemsComponent }
    from '../../components/ftable/organisms/o-card-establishment-items/o-card-establishment-items.component';
import { MPaginatorComponent } from '../../components/ftable/molecules/m-paginator/m-paginator.component';
import { MMenuBarComponent } from '../../components/ftable/molecules/m-menu-bar/m-menu-bar.component';
import { CInitialFilterComponent } from '../../components/containers/c-initial-filter/c-initial-filter.component';
import { CListingFilterComponent } from '../../components/containers/c-listing-filter/c-listing-filter.component';
import {
    ContactDetailsPopupComponent
} from '../../components/modals/contact-details-popup/contact-details-popup.component';
import {
    ClaimConfirmationPopupComponent
} from '../../components/modals/claim-confirmation-popup/claim-confirmation-popup.component';
import {
    NotificationMessagePopupComponent
} from '../../components/modals/notification-message-popup/notification-message-popup.component';
import {
    TableCancellationPopupComponent
} from '../../components/modals/table-cancellation-popup/table-cancellation-popup.component';
import { MSkeletonEstablishmentItemComponent }
    from '../../components/ftable/molecules/m-skeleton-establishment-item/m-skeleton-establishment-item.component';
import { CFormProfileComponent }
    from '../../components/containers/c-form-profile/c-form-profile.component';
import { COverviewMapComponent }
    from '../../components/containers/c-overview-map/c-overview-map.component';
import { OMenuLightboxComponent } from '../../components/ftable/organisms/o-menu-lightbox/o-menu-lightbox.component';
import { CMenuFooterComponent } from '../../components/containers/c-menu-footer/c-menu-footer.component';
import { CAppDownloadsComponent } from '../../components/containers/c-app-downloads/c-app-downloads.component';

/** Ng Prime Modules **/
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TabMenuModule } from 'primeng/tabmenu';
import { SkeletonModule } from 'primeng/skeleton';
import { GalleriaModule } from 'primeng/galleria';
import { IonicModule } from '@ionic/angular';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CCurrentClaimsComponent } from '../../components/containers/c-current-claims/c-current-claims.component';
import { CHistoryClaimsComponent } from '../../components/containers/c-history-claims/c-history-claims.component';
import {
    CWaitingListClaimsComponent
} from '../../components/containers/c-waiting-list-claims/c-waiting-list-claims.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import {
    MLoaderFullscreenComponent
} from '../../components/molecules/m-loader-fullscreen/m-loader-fullscreen.component';



@NgModule({
    declarations: [
        ATextHeadingDirective,
        AButtonDirective,
        ALabelDirective,
        AInputDirective,
        AParagraphDirective,
        MCardEstablishmentItemComponent,
        MCardInitialFilterComponent,
        MHeaderFilterComponent,
        CPopupComponent,
        MSkeletonEstablishmentItemComponent,
        MRestaurantInfoComponent,
        OCardEstablishmentItemsComponent,
        MPaginatorComponent,
        MMenuBarComponent,
        CInitialFilterComponent,
        CListingFilterComponent,
        MBlockLightboxComponent,
        MCardQuestionPhoneComponent,
        ContactDetailsPopupComponent,
        ClaimConfirmationPopupComponent,
        NotificationMessagePopupComponent,
        TableCancellationPopupComponent,
        CFormProfileComponent,
        COverviewMapComponent,
        CCurrentClaimsComponent,
        CHistoryClaimsComponent,
        CWaitingListClaimsComponent,
        OMenuLightboxComponent,
        CMenuFooterComponent,
        CAppDownloadsComponent,
        MLoaderFullscreenComponent,
    ],
    imports: [
        CommonModule,
        DropdownModule,
        CheckboxModule,
        SliderModule,
        RadioButtonModule,
        InputSwitchModule,
        InputTextModule,
        InputNumberModule,
        PaginatorModule,
        ReactiveFormsModule,
        TabMenuModule,
        FormsModule,
        TabViewModule,
        DialogModule,
        ConfirmPopupModule,
        ConfirmDialogModule,
        SkeletonModule,
        GalleriaModule,
        IonicModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        TranslateModule,
    ],
    exports: [
        ATextHeadingDirective,
        AButtonDirective,
        ALabelDirective,
        AInputDirective,
        AParagraphDirective,
        MCardEstablishmentItemComponent,
        MCardInitialFilterComponent,
        MHeaderFilterComponent,
        CListingFilterComponent,
        CPopupComponent,
        OCardEstablishmentItemsComponent,
        MRestaurantInfoComponent,
        DropdownModule,
        CheckboxModule,
        MSkeletonEstablishmentItemComponent,
        SliderModule,
        RadioButtonModule,
        InputSwitchModule,
        InputTextModule,
        InputNumberModule,
        MPaginatorComponent,
        PaginatorModule,
        TabMenuModule,
        MMenuBarComponent,
        ReactiveFormsModule,
        FormsModule,
        CInitialFilterComponent,
        MCardQuestionPhoneComponent,
        MBlockLightboxComponent,
        MSkeletonEstablishmentItemComponent,
        TabViewModule,
        DialogModule,
        ConfirmPopupModule,
        ConfirmDialogModule,
        SkeletonModule,
        ContactDetailsPopupComponent,
        ClaimConfirmationPopupComponent,
        NotificationMessagePopupComponent,
        TableCancellationPopupComponent,
        CFormProfileComponent,
        COverviewMapComponent,
        CCurrentClaimsComponent,
        CHistoryClaimsComponent,
        CWaitingListClaimsComponent,
        OMenuLightboxComponent,
        CMenuFooterComponent,
        CAppDownloadsComponent,
        MLoaderFullscreenComponent,
    ]
})
export class SharedModule {
}
