import { Injectable } from '@angular/core';
import {
    ForwardGeocodeMutationService,
    ReverseGeocodeInput,
    ReverseGeocodeMutation,
    ReverseGeocodeMutationService
} from '../generated/graphql';
import { FetchResult } from '@apollo/client/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GeocodingService {

    public state = {
        coords: new BehaviorSubject({
            lng: 0,
            lat: 0,
        }),
    };

    constructor(
        private reverseGeocodeMutationService: ReverseGeocodeMutationService,
        private forwardGeocodeMutationService: ForwardGeocodeMutationService,
    ) {
    }

    getCoordsFromAddress(address: string) {
        return this.forwardGeocodeMutationService.mutate({input: {address}}).toPromise();
    }

    getAddressFromCoordinates(input: ReverseGeocodeInput): Promise<FetchResult<ReverseGeocodeMutation>> {
        this.state.coords.next({
            lng: input.longitude,
            lat: input.latitude,
        });
        return this.reverseGeocodeMutationService.mutate({input}).toPromise();
    }

}
