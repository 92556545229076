import { Component, OnInit } from '@angular/core';
import { TablesService } from '../../../services/tables.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '../../../services/events.service';

interface MenuItem {
    id: string;
    label: string;
    icon: string;
    badge: BehaviorSubject<number>;
    url: string;
}
@Component({
    selector: 'app-c-menu-footer',
    templateUrl: './c-menu-footer.component.html',
    styleUrls: ['./c-menu-footer.component.scss'],
})
export class CMenuFooterComponent implements OnInit {

    public items: MenuItem[] = [];

    constructor(
        private tablesService: TablesService,
        public translate: TranslateService,
        private events: Events,
    ) {
    }

    ngOnInit() {
        this.items = [
            {
                id: 't1',
                label: this.translate.instant('menu.freetable'),
                icon: 'assets/images/svg/icon-table.svg',
                badge: new BehaviorSubject(null),
                url: '/overview'
            },
            {
                id: 't2',
                label: this.translate.instant('menu.bookings'),
                icon: 'assets/images/svg/icon-bell.svg',
                badge: new BehaviorSubject(null),
                url: '/my-tables'

            },
            {
                id: 't3',
                label: this.translate.instant('menu.my-profile'),
                icon: 'assets/images/svg/icon-person.svg',
                badge: new BehaviorSubject(null),
                url: '/my-profile'
            }
        ];
        this.events.subscribe('refresh-menu', () => {
            console.log('refreshMenu event received');
            this.refreshMenu();
        });
    }

    refreshMenu() {
        this.items[0].label = this.translate.instant('menu.freetable');
        this.items[1].label = this.translate.instant('menu.bookings');
        this.items[2].label = this.translate.instant('menu.my-profile');
    }
}
