import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { GeocodingService } from '../../../../services/geocoding.service';
import { ListingService } from '../../../../services/listing.service';
import { Platform } from '@ionic/angular';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

@Component({
    selector: 'app-m-header-filter',
    templateUrl: './m-header-filter.component.html',
    styleUrls: ['./m-header-filter.component.scss'],
})
export class MHeaderFilterComponent implements OnInit {

    @Input() isListing = false;
    @Input() public formValues: BehaviorSubject<{
        latitude: number;
        longitude: number;
        searchTerm: string;
        numOfPersons: number;
        radius: number;
    }>;
    @Output() formChange = new EventEmitter();
    @Output() emitGeolocation = new EventEmitter();

    public filterFormGroup: FormGroup;
    public getCurrentLocationIsUsable = true;
    public geoIsLoading = false;
    public locationIsTyped = false;
    private previousAddress = '';

    constructor(
        private platform: Platform,
        private geolocation: Geolocation,
        public translate: TranslateService,
        private fb: FormBuilder,
        private geocodingService: GeocodingService,
    ) {
    }

    public ngOnInit() {
        this.getCurrentLocationIsUsable = (localStorage.getItem('getCurrentLocationIsUsable') === 'true');
        this.filterFormGroup = this.fb.group({
            latitude: [],
            longitude: [],
            searchTerm: ['', Validators.required],
            numOfPersons: ['', Validators.required],
            radius: ['', Validators.required],
        });

        this.formValues.pipe(
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        ).subscribe((formValues) => {
            this.filterFormGroup.setValue({
                latitude: formValues.latitude,
                longitude: formValues.longitude,
                searchTerm: formValues.searchTerm,
                numOfPersons: formValues.numOfPersons,
                radius: Number(formValues.radius)
            });
        });
    }

    public resetHeaderLocationFilter() {
        this.locationIsTyped = true;
        this.previousAddress = this.filterFormGroup.get('searchTerm').value;
        this.filterFormGroup.get('searchTerm').setValue('');
    }

    public checkResetHeaderLocationFilter() {
        this.locationIsTyped = false;
        const currentValue = this.filterFormGroup.get('searchTerm').value;
        if (currentValue === '') {
            this.filterFormGroup.get('searchTerm').setValue(this.previousAddress);
        }
    }

    public handleHeaderLocationFilter() {
        this.locationIsTyped = false;
        if (!this.geoIsLoading) {
            this.geoIsLoading = true;
            this.geocodingService.getCoordsFromAddress(this.filterFormGroup.get('searchTerm').value).then(res => {
                console.log('handleHeaderLocationFilter', res);
                this.filterFormGroup.get('latitude').setValue(res.data.forwardGeocode.latitude);
                this.filterFormGroup.get('longitude').setValue(res.data.forwardGeocode.longitude);
                this.handleFilter();
                this.geoIsLoading = false;
            });
        }
    }

    public handleFilter() {
        if (this.filterFormGroup.get('latitude').value && this.filterFormGroup.get('searchTerm').value) {
            this.formChange.emit(this.filterFormGroup.value);
        } else {
            alert('Address needed');
        }
    }

    public requestCurrentLocation() {
        this.geoIsLoading = true;
        if (this.platform.is('cordova')) {
            this.geolocation.getCurrentPosition().then((location) => {
                this.emitGeolocation.emit(location);
                this.getCurrentLocationIsUsable = true;
                this.geoIsLoading = false;
            }).catch((error) => {
                console.log('Error getting location', error);
            });
        } else {
            try {
                if (navigator.permissions
                    && navigator.permissions.query
                    && navigator.geolocation
                ) {
                    navigator.permissions.query({name: 'geolocation'}).then((result) => {
                        console.log('navigator.permissions', result);
                        if (result.state === 'granted' || result.state === 'prompt') {
                            navigator.geolocation.getCurrentPosition((location) => {
                                this.geoIsLoading = false;
                                this.emitGeolocation.emit(location);
                                console.log(location);
                            });
                        } else {
                            this.geoIsLoading = false;
                            this.getCurrentLocationIsUsable = false;
                        }
                    });
                } else {
                    this.geoIsLoading = false;
                    this.getCurrentLocationIsUsable = false;
                }
            } catch (e) {
                this.geoIsLoading = false;
                this.getCurrentLocationIsUsable = false;
            }
        }
    }

}
