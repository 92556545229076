import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ListingService } from '../../../../services/listing.service';
import { BusinessHour, Distance, RestaurantsQuery, Translatable } from '../../../../generated/graphql';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import * as moment from 'moment';
import { finalize, map, takeUntil, takeWhile } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../services/language.service';

//TODO: This has to come from the backend
const EstablishmentStatusEnum = {
    claimable: 'claimable',
    claimed: 'claimed',
    full: 'full',
    waiting: 'waiting_list',
    cancelled: 'cancelled',
    rejected: 'rejected',
};


@Component({
    selector: 'app-m-card-establishment-item',
    templateUrl: './m-card-establishment-item.component.html',
    styleUrls: ['./m-card-establishment-item.component.scss'],
})

export class MCardEstablishmentItemComponent implements OnInit, OnDestroy {

    @Input() id: string;
    @Input() image = '/assets/images/no-image.png';
    @Input() title = 'Restaurant naam hier';
    @Input() location = 'teststraat 14, Haarlem';
    @Input() distance: Distance;
    @Input() rating: number;
    @Input() telephoneNumber = '012345678';
    @Input() status = 'claimable';
    @Input() type = '';
    @Input() price = 0;
    @Input() extraInfo: Translatable;
    @Input() time = '';
    @Input() amountOfPersons: number;
    @Input() businessHours: BusinessHour[] = [];
    @Input() timeOfClaim: string | null = null;
    @Input() tableId = '';

    @Output() claimingTimerComplete = new EventEmitter();
    @Output() emitClaim = new EventEmitter();

    public subscriptions = new Subscription();
    public EstablishmentStatusEnum = EstablishmentStatusEnum;

    public priceIndication: string;

    public restaurantsQueryResponse$: BehaviorSubject<ApolloQueryResult<RestaurantsQuery>> = this.listingService.state.restaurants;

    public isAbleToShare = false;
    public isClaimedRecently$ = new BehaviorSubject(false);
    public claimPercentage = 0;

    constructor(
        private listingService: ListingService,
        public translate: TranslateService,
        public languageService: LanguageService,
    ) {
    }


    ngOnInit() {
        console.log('extraInfo', this.extraInfo);
        if (this.price) {
            this.priceIndication = this.listingService.priceIndication(this.price);
        }
        const now = moment().add('0', 'seconds');
        const claimedAt = moment(this.timeOfClaim);
        const passedSeconds = this.passedSeconds(now, claimedAt);
        if (passedSeconds < 65 && this.status === 'pending') {
            this.isClaimedRecently$.next(true);
            this.subscriptions.add(interval(1000).pipe(
                map((result) => result + passedSeconds),
                takeWhile((result) => result < 65 && this.status === 'pending'),
                finalize(() => {
                    this.isClaimedRecently$.next(false);
                    this.claimingTimerComplete.emit();
                }),
            ).subscribe((res) => {
                this.claimPercentage = (res / 65) * 100;
            }));
        }
    }

    public share() {
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public handleTime(date: string) {
        // console.log('handleTime', date);
        return moment(date).format('HH:mm');
    }

    public handleOpeningTime(businessHours: BusinessHour[]) {
        if (businessHours) {
            const currentDay = moment().day();
            const found = businessHours.filter((businessHour) => Number(businessHour.dayOfWeek) === Number(currentDay))[0];
            if (found) {
                return this.languageService.getWeekDay(currentDay) + `: ${found.openTime} - ${found.closeTime}`;
            } else {
                return this.translate.instant('general.closed-today');
            }
        }
    }

    public handleClaim() {
        const handClaimPayload = {
            id: this.id,
            status: this.status,
            tableId: this.tableId,
        };
        this.emitClaim.emit(handClaimPayload);
        console.log('MCardEstablishmentItemComponent handleClaim', handClaimPayload);
    }

    private passedSeconds(start, end) {
        return moment.duration(start.diff(end)).asSeconds();
    }
}
