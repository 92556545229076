import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-m-skeleton-establishment-item',
    templateUrl: './m-skeleton-establishment-item.component.html',
    styleUrls: ['./m-skeleton-establishment-item.component.scss'],
})
export class MSkeletonEstablishmentItemComponent implements OnInit {

    constructor(public translate: TranslateService) {
    }

    ngOnInit() {
    }

}
