import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-contact-details-popup',
    templateUrl: './contact-details-popup.component.html',
    styleUrls: ['./contact-details-popup.component.scss'],
})
export class ContactDetailsPopupComponent implements OnInit {


    public contactForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.contactForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            phone: ['', [Validators.required]]
        });
    }


}
