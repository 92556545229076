import { NgModule } from '@angular/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';

const uri = environment.graphQLEndpoint;

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

export const createApollo = (httpLink: HttpLink): ApolloClientOptions<any> => ({
    link: ApolloLink.from([
        httpLink.create({
            uri,
        }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions,
    headers: {
        'Accept-Language': localStorage.getItem('language') || 'nl',
    },
});

@NgModule({
    providers: [
        Apollo,
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {
}
