import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-table-cancellation-popup',
    templateUrl: './table-cancellation-popup.component.html',
    styleUrls: ['./table-cancellation-popup.component.scss'],
})
export class TableCancellationPopupComponent implements OnInit {

    constructor(public translate: TranslateService) {
    }

    ngOnInit() {
    }

}
