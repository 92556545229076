import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterConsumerInput } from '../../../generated/graphql';
import { ConsumerService } from '../../../services/consumer.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';
import { Events } from '../../../services/events.service';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-c-form-profile',
    templateUrl: './c-form-profile.component.html',
    styleUrls: ['./c-form-profile.component.scss'],
})
export class CFormProfileComponent implements OnInit {
    @Input()
    public inPopup = false;

    public profileFormGroup: FormGroup;
    public saveCompleteModalOpen = false;
    public me: any;
    public countries: any;

    constructor(
        private fb: FormBuilder,
        private consumerService: ConsumerService,
        public languageService: LanguageService,
        public translate: TranslateService,
        private events: Events,
        private modalController: ModalController,
    ) {
    }

    public async ngOnInit() {
        this.countries = JSON.parse(localStorage.getItem('countries'));
        this.profileFormGroup = this.fb.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            phone: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            country: [this.countries[0].id, [Validators.required]],
        });
        this.consumerService.state.currentConsumerInfo.subscribe((res) => {
            this.me = {
                first_name: this.consumerService.state.currentConsumerInfo.value.first_name,
                last_name: this.consumerService.state.currentConsumerInfo.value.last_name,
                phone: this.consumerService.state.currentConsumerInfo.value.phone,
                email: this.consumerService.state.currentConsumerInfo.value.email,
                country: this.consumerService.state.currentConsumerInfo.value.country,
            };
            this.setMe();
        });
    }

    public setMe() {
        console.log('setMe');
        if (this.profileFormGroup) {
            console.log('profileFormGroup available');
            this.profileFormGroup.patchValue({
                first_name: this.me.first_name,
                last_name: this.me.last_name,
                email: this.me.email,
                phone: this.me.phone,
                country: this.me.country
            });
        }
    }

    public changeLanguage(event: any) {
        this.translate.use(event.detail.value);
        this.languageService.setLanguage(event.detail.value);
        this.setMe();
        this.events.publish('refresh-menu');
    }

    public handleUpdateOrCreate(event: Event) {
        if (this.consumerService.state.consumerId.value) {
            this.initUpdateUserCall();
        } else {
            this.initCreateUserCall();
        }
    }

    public initUpdateUserCall() {
        if (this.profileFormGroup.valid) {
            const id = this.consumerService.state.consumerId.value;
            const input = {
                first_name: this.profileFormGroup.controls.first_name.value,
                last_name: this.profileFormGroup.controls.last_name.value,
                phone: this.profileFormGroup.controls.phone.value,
                email: this.profileFormGroup.controls.email.value,
                fcm_token: this.consumerService.state.fcmToken.getValue(),
                locale: localStorage.getItem('language') || 'nl',
                country: {
                    connect: this.profileFormGroup.controls.country.value,
                },
            } as RegisterConsumerInput;
            this.consumerService.updateConsumer(input, id).then((response) => {
                console.log('updateConsumer response', response);
                this.saveCompleteModalOpen = true;
                setTimeout(() => {
                    if (this.inPopup) {
                        this.dismissModal();
                    } else {
                        console.log('not in popup so show form again');
                        this.saveCompleteModalOpen = false;
                    }
                }, 1500);
            });
        }
    }

    public initCreateUserCall() {
        if (this.profileFormGroup.valid) {
            const input = {
                first_name: this.profileFormGroup.controls.first_name.value,
                last_name: this.profileFormGroup.controls.last_name.value,
                phone: this.profileFormGroup.controls.phone.value,
                email: this.profileFormGroup.controls.email.value,
                fcm_token: this.consumerService.state.fcmToken.getValue(),
                locale: localStorage.getItem('language') || 'nl',
                country: {
                    connect: this.profileFormGroup.controls.country.value
                },
            } as RegisterConsumerInput;
            this.consumerService.registerConsumer(input).then(() => {
                this.saveCompleteModalOpen = true;
                setTimeout(() => {
                    if (this.inPopup) {
                        this.dismissModal();
                    } else {
                        console.log('not in popup so show form again');
                        this.saveCompleteModalOpen = false;
                    }
                }, 1500);
            });
        }
    }

    public async dismissModal() {
        await this.modalController.dismiss({
            dismissed: true
        });
    }
}
