import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[appATextHeading]',
})
export class ATextHeadingDirective {

    @Input() isLarge: boolean = false;
    @Input() isSmall: boolean = false;
    @Input() isTiny: boolean = false;
    @Input() isCentered: boolean = false;

    @HostBinding('class') get classes(): string {
        const classes: Array<string> = [];

        classes.push('a-text-heading');

        if (this.isLarge) {
            classes.push('a-text-heading--isLarge');
        }
        if (this.isSmall) {
            classes.push('a-text-heading--isSmall');
        }
        if (this.isTiny) {
            classes.push('a-text-heading--isTiny');
        }
        if (this.isCentered) {
            classes.push('a-text-heading--isCentered');
        }

        return classes.join(' ');
    }

}
