import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../services/language.service';
import { Events } from '../../../../services/events.service';
import { Platform } from '@ionic/angular';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { GeocodingService } from '../../../../services/geocoding.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Globals } from '../../../../config/globals';

@Component({
    selector: 'app-m-card-initial-filter',
    templateUrl: './m-card-initial-filter.component.html',
    styleUrls: ['./m-card-initial-filter.component.scss'],
})
export class MCardInitialFilterComponent implements OnInit {

    @Output() emitSubmit = new EventEmitter();
    @Output() emitGeolocation = new EventEmitter();

    @Input() public formValues: BehaviorSubject<{
        isDefault: boolean;
        searchTerm: string;
        numOfPersons: number;
        radius: number;
        latitude: number;
        longitude: number;
    }>;

    public filterFormGroup: FormGroup;
    public getCurrentLocationIsUsable = true;
    public geoIsLoading = false;
    public hasBeenGeocoded = false;
    public isDisabled = false;
    private onMobile = false;

    constructor(
        public translate: TranslateService,
        public languageService: LanguageService,
        private platform: Platform,
        private geolocation: Geolocation,
        private fb: FormBuilder,
        private events: Events,
        private geocodingService: GeocodingService,
    ) {
    }

    ngOnInit() {
        console.log('Init initial filter component');
        this.onMobile = this.platform.is('hybrid');
        this.filterFormGroup = this.fb.group({
            latitude: [],
            longitude: [],
            searchTerm: ['', Validators.required],
            numOfPersons: ['', Validators.required],
            radius: ['', Validators.required],
        });

        this.formValues.pipe(
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        ).subscribe((formValues) => {
            this.filterFormGroup.setValue({
                latitude: formValues.latitude,
                longitude: formValues.longitude,
                searchTerm: formValues.searchTerm,
                numOfPersons: formValues.numOfPersons,
                radius: Number(formValues.radius)
            });
        });

        setTimeout(() => {
            this.requestCurrentLocation();
        }, 500);
    }

    public handleLocationFilter() {
        this.geoIsLoading = true;
        this.geocodingService.getCoordsFromAddress(this.filterFormGroup.get('searchTerm').value).then(res => {
            console.log('handleLocationFilter', res);
            this.filterFormGroup.get('latitude').setValue(res.data.forwardGeocode.latitude);
            this.filterFormGroup.get('longitude').setValue(res.data.forwardGeocode.longitude);
            this.geoIsLoading = false;
        });
    }

    public requestCurrentLocation() {
        console.log('MCardInitialFilterComponent --> requestCurrentLocation');

        if (this.onMobile) {
            this.geoIsLoading = true;
            console.log('MCardInitialFilterComponent --> on hybrid so use the geolocation plugin');
            this.geolocation.getCurrentPosition().then((location) => {
                this.emitGeolocation.emit(location);
                this.getCurrentLocationIsUsable = true;
                this.geoIsLoading = false;
            }).catch((error) => {
                console.log('Error getting location', error);
                this.geoIsLoading = false;
                this.getCurrentLocationIsUsable = false;
            });
        } else {
            console.log('MCardInitialFilterComponent --> on desktop so check permission');
            try {
                this.geoIsLoading = true;
                navigator.geolocation.getCurrentPosition((location) => {
                    this.geoIsLoading = false;
                    this.getCurrentLocationIsUsable = true;
                    this.emitGeolocation.emit(location);
                    console.log(location);
                });
            } catch (e) {
                this.geoIsLoading = false;
                this.getCurrentLocationIsUsable = false;
            }
        }
        localStorage.setItem('getCurrentLocationIsUsable', String(this.getCurrentLocationIsUsable));
    }

    public changeLanguage(event: any) {
        this.translate.use(event.detail.value);
        this.languageService.setLanguage(event.detail.value);
        this.events.publish('refresh-menu');
    }

    public emitForm() {
        this.emitSubmit.emit(this.filterFormGroup.value);
    }
}
