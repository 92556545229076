import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Restaurant } from '../../../../generated/graphql';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-o-card-establishment-items',
    templateUrl: './o-card-establishment-items.component.html',
    styleUrls: ['./o-card-establishment-items.component.scss'],
})
export class OCardEstablishmentItemsComponent implements OnInit {


    @Output() emitClaim = new EventEmitter();
    @Input() establishments: Restaurant[] = [];

    public timeOfClaimDate = String(new Date());

    constructor(public translate: TranslateService) {
    }

    ngOnInit() {
    }

    public emitOnClaim(childData) {
        this.emitClaim.emit(childData);
    }
}
