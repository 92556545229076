import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-notification-message-popup',
    templateUrl: './notification-message-popup.component.html',
    styleUrls: ['./notification-message-popup.component.scss'],
})
export class NotificationMessagePopupComponent implements OnInit {

    constructor(
        private modalController: ModalController,
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
    }

    public async handleClose() {
        await this.modalController.dismiss({
            dismissed: true
        });
    }
}
