import { Injectable } from '@angular/core';
import {
    Claim_Type,
    ClaimTableInput,
    ClaimTableMutation,
    ClaimTableMutationService,
    ConsumerNotificationsQueryService,
    CreateNotificationInput,
    CreateNotificationMutation,
    CreateNotificationMutationService,
    CurrentConsumerClaimsQueryService,
    CancelClaimTableMutationService, CancelClaimTableInput, Scalars, DeleteNotificationMutationService
} from '../generated/graphql';
import { FetchResult } from '@apollo/client/core';
import { ConsumerService } from './consumer.service';
import { ListingService } from './listing.service';
import { StorageService } from './ionic-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TablesService {

    currentTableInClaimProcess = {
        id: new BehaviorSubject(null) as BehaviorSubject<string | null>,
        timeStamp: new BehaviorSubject(null) as BehaviorSubject<Date | null>,
    };

    restaurantIdFromPush = new BehaviorSubject(null) as BehaviorSubject<string | null>;

    constructor(
        private claimTableMutationService: ClaimTableMutationService,
        private createNotificationMutationService: CreateNotificationMutationService,
        private deleteNotificationMutationService: DeleteNotificationMutationService,
        private cancelClaimTableMutationService: CancelClaimTableMutationService,
        private consumerNotificationsQueryService: ConsumerNotificationsQueryService,
        private currentConsumerClaimsQueryService: CurrentConsumerClaimsQueryService,
        private consumerService: ConsumerService,
        private listingService: ListingService,
        private storageService: StorageService,
    ) {
    }

    public createNotification(input): Promise<FetchResult<CreateNotificationMutation>> {
        const inputObject = {
            consumer: {
                connect: this.consumerService.state.consumerId.value
            },
            restaurant: {
                connect: input.id
            },
            numOfPersons: Number(this.listingService.listingFilterValues.getValue().numOfPersons),
        } as CreateNotificationInput;
        return this.createNotificationMutationService.mutate({input: inputObject}, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.consumerService.state.token.getValue()}`
                },
            },
        }).toPromise();
    }

    public deleteNotification(id: Scalars['UUID']) {
        return this.deleteNotificationMutationService.mutate({id}, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.consumerService.state.token.getValue()}`
                },
            },
        }).toPromise();
    }

    public claimTable(input): Promise<FetchResult<ClaimTableMutation>> {
        const inputObject = {
            consumer: {
                connect: this.consumerService.state.consumerId.value
            },
            restaurant: {
                connect: input.id
            },
            numOfPersons: Number(this.listingService.listingFilterValues.getValue().numOfPersons),
        } as ClaimTableInput;

        this.currentTableInClaimProcess.id.next(input.id);
        this.currentTableInClaimProcess.timeStamp.next(new Date());

        return this.claimTableMutationService.mutate({input: inputObject}, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                    Authorization: `Bearer ${this.consumerService.state.token.getValue()}`
                },
            },
        }).toPromise().then((res) => {
            // eslint-disable-next-line no-underscore-dangle
            if (res.data.claimTable.__typename === 'Result') {
                throw res.data.claimTable.message;
                return res;
            }
            return res;
        });
    }

    public cancelClaimTable(input: CancelClaimTableInput) {
        return this.cancelClaimTableMutationService.mutate({input},
            {
                context: {
                    headers: {
                        'Accept-Language': localStorage.getItem('language') || 'nl',
                        Authorization: `Bearer ${this.consumerService.state.token.getValue()}`
                    },
                },
            }).toPromise().then(() => {

        });
    }

    public async getHistoryConsumerClaims() {
        return this.storageService.get('consumerId').then((id) => {
            if (!id) {
                return;
            }
            ;
            return this.currentConsumerClaimsQueryService.fetch(
                {
                    input: {
                        page: 1,
                        first: 5,
                        consumerId: id,
                        type: Claim_Type.History
                    }
                },
                {
                    context: {
                        headers: {
                            'Accept-Language': localStorage.getItem('language') || 'nl',
                            Authorization: `Bearer ${this.consumerService.state.token.getValue()}`
                        },
                    },
                }
            ).toPromise();
        });
    }

    public async getCurrentConsumerClaims() {
        return this.storageService.get('consumerId').then((id) => {
            if (!id) {
                return;
            }
            ;
            return this.currentConsumerClaimsQueryService.fetch(
                {
                    input: {
                        page: 1,
                        first: 5,
                        consumerId: id,
                        type: Claim_Type.Current
                    }
                },
                {
                    context: {
                        headers: {
                            'Accept-Language': localStorage.getItem('language') || 'nl',
                            Authorization: `Bearer ${this.consumerService.state.token.getValue()}`
                        },
                    },
                }
            ).toPromise();
        });
    }

    public async getCurrentNotifications() {
        return this.storageService.get('consumerId').then((id) => {
            if (!id) {
                return;
            }
            ;
            return this.consumerNotificationsQueryService.fetch(
                {
                    input: {
                        consumer: {
                            connect: id,
                        },
                        address: this.listingService.listingFilterValues.getValue().searchTerm,
                        coordinates: {
                            latitude: this.listingService.listingFilterValues.getValue().latitude,
                            longitude: this.listingService.listingFilterValues.getValue().longitude,
                        }
                    }
                },
                {
                    context: {
                        headers: {
                            'Accept-Language': localStorage.getItem('language') || 'nl',
                            Authorization: `Bearer ${this.consumerService.state.token.getValue()}`
                        },
                    },
                }
            ).toPromise();
        });
    }
}
