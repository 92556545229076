import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-m-block-lightbox',
    templateUrl: './m-block-lightbox.component.html',
    styleUrls: ['./m-block-lightbox.component.scss'],
})

export class MBlockLightboxComponent {

    @Input() public id = '';
    @Input() public images = [];

    public isAbleToShare = false;

    responsiveOptions2: any[] = [
        {
            breakpoint: '1500px',
            numVisible: 5
        },
        {
            breakpoint: '1024px',
            numVisible: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    displayBasic: boolean;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public translate: TranslateService,
    ) {
    }

    public share() {
    }

    public handleBack() {
        this.router.navigate(['/overview']);
    }

}
