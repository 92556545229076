import { Component, OnInit } from '@angular/core';
import { ListingService } from '../../../services/listing.service';
import { NavController } from '@ionic/angular';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { ReverseGeocodeInput } from '../../../generated/graphql';

@Component({
    selector: 'app-c-initial-filter',
    templateUrl: './c-initial-filter.component.html',
    styleUrls: ['./c-initial-filter.component.scss'],
})
export class CInitialFilterComponent implements OnInit {

    public listingFilterValues$ = this.listingService.listingFilterValues;
    public formCanSubmit = true;
    public isLoading = false;

    constructor(
        private listingService: ListingService,
        private geocodingService: GeocodingService,
        private router: NavController,
    ) {
    }

    ngOnInit() {
    }

    public handleInitialFilter(formValues) {
        this.isLoading = true;
        console.log('handleInitialFilter', formValues);
        const searchTerm = formValues.searchTerm;
        this.geocodingService.getCoordsFromAddress(searchTerm).then(res => {
            const typeCastedFormValues = {
                ...formValues,
                latitude: Number(res.data.forwardGeocode.latitude),
                longitude: Number(res.data.forwardGeocode.longitude),
                isDefault: false,
            };
            console.log('handleInitialFilter typeCastedFormValues', JSON.stringify(typeCastedFormValues));
            this.listingService.listingFilterValues.next(typeCastedFormValues);
            this.router.navigateRoot(['/overview']);
            this.isLoading = false;
        });
    }

    public handleGeoLocation(event) {
        const reverseGeocodeInput: ReverseGeocodeInput = {
            latitude: Number(event.coords.latitude),
            longitude: Number(event.coords.longitude),
        };
        // console.log(reverseGeocodeInput);
        this.geocodingService.getAddressFromCoordinates(reverseGeocodeInput).then((response) => {
            console.log(response.data.reverseGeocode.address);
            const newState = {
                ...this.listingService.listingFilterValues.getValue(),
                ...reverseGeocodeInput,
                default: false,
                searchTerm: response.data.reverseGeocode.address,
            };
            console.log('newState after geoCode', newState);
            this.listingService.listingFilterValues.next(newState);
            this.listingService.hasBeenGeocoded.next(true);
        });
    }

}
