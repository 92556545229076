import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-c-popup',
    templateUrl: './c-popup.component.html',
    styleUrls: ['./c-popup.component.scss'],
})
export class CPopupComponent implements OnInit {

    @Input()
    public inPopup = false;


    constructor(public translate: TranslateService,) {
    }

    ngOnInit() {
    }

}
