import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-o-menu-lightbox',
    templateUrl: './o-menu-lightbox.component.html',
    styleUrls: ['./o-menu-lightbox.component.scss'],
})
export class OMenuLightboxComponent {

    @Input() public images = [];

    public responsiveOptions: any[] = [
        {
            breakpoint: '1500px',
            numVisible: 2
        },
        {
            breakpoint: '1024px',
            numVisible: 2
        },
        {
            breakpoint: '768px',
            numVisible: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    public displayBasic: boolean;

    constructor(public translate: TranslateService) {
    }

}
