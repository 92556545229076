import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  /**
   * The `UUID` scalar type represents a unique identifier, often used to
   * refetch an object or as key for a cache. The ID type appears in a JSON
   * response as a String; however, it is not intended to be human-readable.
   */
  UUID: any;
  Upload: any;
  /** The `Url` scalar type represents a valid url */
  Url: any;
};

export type AccessToken = {
  __typename?: 'AccessToken';
  fcmToken?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  user: User;
};

export type AddRestaurantToFavouriteInput = {
  consumer: ConsumerBelongsTo;
  restaurant: RestaurantBelongsTo;
};

export type AvailableTables = {
  __typename?: 'AvailableTables';
  count: Scalars['Int'];
  numOfPersons: Scalars['Int'];
};

export type BusinessHour = {
  __typename?: 'BusinessHour';
  closeTime?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  openTime?: Maybe<Scalars['String']>;
};

export type BusinessHoursInput = {
  restaurant: RestaurantBelongsTo;
};

export enum Claim_Type {
  Current = 'CURRENT',
  History = 'HISTORY'
}

export type CancelClaimTableInput = {
  consumer: ConsumerBelongsTo;
  table: TableBelongsTo;
};

export type CategoriesBelongsToMany = {
  connect?: Maybe<Array<Scalars['UUID']>>;
};

export type Category = {
  __typename?: 'Category';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  restaurants: Array<Restaurant>;
  updatedAt: Scalars['DateTime'];
};

export type CategoryBelongsTo = {
  connect: Scalars['UUID'];
};

export type ClaimTableInput = {
  consumer: ConsumerBelongsTo;
  numOfPersons: Scalars['Int'];
  restaurant: RestaurantBelongsTo;
};

export type Consumer = {
  __typename?: 'Consumer';
  claims?: Maybe<Array<Maybe<Table>>>;
  country: Country;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  favourites: Array<Restaurant>;
  fcmTokens: Array<Maybe<FcmToken>>;
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  notifications: Array<Notification>;
  phone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ConsumerBelongsTo = {
  connect: Scalars['UUID'];
};

export type ConsumerClaimsInput = {
  consumerId: Scalars['UUID'];
  date?: Maybe<Scalars['Date']>;
  first: Scalars['Int'];
  page: Scalars['Int'];
  type: Claim_Type;
};

export type ConsumerInput = {
  country: CountryBelongsTo;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ConsumerNotificationsInput = {
  address?: Maybe<Scalars['String']>;
  consumer: ConsumerBelongsTo;
  coordinates?: Maybe<CoordinatesInput>;
  countryId?: Maybe<Scalars['UUID']>;
};

export type ConsumersBelongsToMany = {
  connect?: Maybe<Array<Scalars['UUID']>>;
};

export type CoordinatesInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type Country = {
  __typename?: 'Country';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currencySymbol: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};

export type CountryBelongsTo = {
  connect: Scalars['UUID'];
};

export type CreateBusinessHoursInput = {
  closeTime?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openTime?: Maybe<Scalars['String']>;
};

export type CreateFcmTokenInput = {
  token: Scalars['String'];
  user: UserBelongsTo;
};

export type CreateMenuInput = {
  attachment: Array<Maybe<Scalars['Upload']>>;
  restaurant: RestaurantBelongsTo;
};

export type CreateNotificationInput = {
  consumer: ConsumerBelongsTo;
  numOfPersons: Scalars['Int'];
  restaurant: RestaurantBelongsTo;
};

export type CreateRestaurantInput = {
    categories: CategoriesBelongsToMany;
    cityName?: Maybe<Scalars['String']>;
    extraInfo?: Maybe<ExtraInfoTranslations>;
    logo?: Maybe<Scalars['Upload']>;
    minArrivingTime?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    owner: OwnerBelongsTo;
    phone: Scalars['String'];
    postalCode?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Int']>;
    streetName?: Maybe<Scalars['String']>;
    streetNumber?: Maybe<Scalars['String']>;
};

export type CreateRestaurantPhotoInput = {
  image: Array<Maybe<Scalars['Upload']>>;
  restaurant: RestaurantBelongsTo;
};

export type CreateSpecialDayInput = {
  closeTime?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  openTime?: Maybe<Scalars['String']>;
  specialDayEndDate?: Maybe<Scalars['Date']>;
  specialDayName?: Maybe<Scalars['String']>;
  specialDayStartDate?: Maybe<Scalars['Date']>;
};

export type CreateTableInput = {
  numOfPersons: Scalars['Int'];
  restaurant: RestaurantBelongsTo;
  type: TableTypeEnum;
};

export type DeleteLogoInput = {
  restaurant: RestaurantBelongsTo;
};

export type DeleteTableInput = {
  numOfPersons: Scalars['Int'];
  restaurant: RestaurantBelongsTo;
};

export type Distance = {
  __typename?: 'Distance';
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export type EditBusinessHoursInput = {
  restaurant: RestaurantBelongsTo;
};

export type EmailVerificationResponse = {
  __typename?: 'EmailVerificationResponse';
  status: EmailVerificationStatus;
};

export enum EmailVerificationStatus {
  /** VERIFIED */
  Verified = 'VERIFIED'
}

export type FcmToken = {
  __typename?: 'FcmToken';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
  reset_password_url: ResetPasswordUrlInput;
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: ForgotPasswordStatus;
};

export enum ForgotPasswordStatus {
  /** EMAIL_SENT */
  EmailSent = 'EMAIL_SENT'
}

export type ForwardGeocodeInput = {
  address: Scalars['String'];
};

export type Geocode = {
  __typename?: 'Geocode';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type LoginInput = {
  email: Scalars['String'];
  fcm_token?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  message: Scalars['String'];
  status: LogoutStatus;
};

export enum LogoutStatus {
  /** TOKEN_REVOKED */
  TokenRevoked = 'TOKEN_REVOKED'
}

export type Menu = {
  __typename?: 'Menu';
  attachment?: Maybe<Scalars['Url']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  priority?: Maybe<Scalars['Int']>;
  restaurant: Restaurant;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type MenuAttachmentArgs = {
  variant?: Maybe<MenuAttachmentVariant>;
};

/** Allowed resizes for the `variant` argument on the query `attachment`. */
export enum MenuAttachmentVariant {
  Big = 'BIG',
  Medium = 'MEDIUM',
  Original = 'ORIGINAL',
  Small = 'SMALL'
}

export type Mutation = {
  __typename?: 'Mutation';
  addRestaurantToFavourite?: Maybe<Consumer>;
  cancelClaimTable?: Maybe<TableResponse>;
  claimTable?: Maybe<TableResponse>;
  createBusinessHours?: Maybe<Array<Maybe<BusinessHour>>>;
  createFcmToken: FcmToken;
  createNotification: Notification;
  createRestaurant: Restaurant;
  createSpecialDays?: Maybe<Array<Maybe<SpecialDay>>>;
  createTable?: Maybe<Table>;
  deactivateConsumer?: Maybe<Consumer>;
  deactivateOwner?: Maybe<Owner>;
  deleteConsumer?: Maybe<Consumer>;
  deleteFcmToken?: Maybe<FcmToken>;
  deleteLogo: Restaurant;
  deleteMenu?: Maybe<Menu>;
  deleteNotification?: Maybe<Notification>;
  deleteOwner?: Maybe<Consumer>;
  deleteRestaurantPhoto?: Maybe<RestaurantPhoto>;
  deleteSpecialDay?: Maybe<SpecialDay>;
  deleteTable?: Maybe<TableResponse>;
  forgotPassword: ForgotPasswordResponse;
  forwardGeocode: Geocode;
  login: AccessToken;
  logout: LogoutResponse;
  menuMultipleUpload?: Maybe<Array<Maybe<Menu>>>;
  reactivateConsumer?: Maybe<Consumer>;
  reactivateOwner?: Maybe<Owner>;
  registerConsumer: RegisterResponse;
  registerOwner: RegisterResponse;
  rejectClaimTable?: Maybe<TableResponse>;
  resendEmailVerification: ResendEmailVerificationResponse;
  resetPassword: ResetPasswordResponse;
  restaurantPhotoMultipleUpload?: Maybe<Array<Maybe<RestaurantPhoto>>>;
  reverseGeocode: ReverseGeocode;
  updateConsumer: Consumer;
  updateOwner: Owner;
  updatePassword: UpdatePasswordResponse;
  updatePreferredLocale?: Maybe<User>;
  updateRestaurant: Restaurant;
  uploadLogo: Restaurant;
  verifyEmail: EmailVerificationResponse;
};


export type MutationAddRestaurantToFavouriteArgs = {
  input: AddRestaurantToFavouriteInput;
};


export type MutationCancelClaimTableArgs = {
  input: CancelClaimTableInput;
};


export type MutationClaimTableArgs = {
  input: ClaimTableInput;
};


export type MutationCreateBusinessHoursArgs = {
  input?: Maybe<Array<Maybe<CreateBusinessHoursInput>>>;
  restaurant: RestaurantBelongsTo;
};


export type MutationCreateFcmTokenArgs = {
  input: CreateFcmTokenInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


export type MutationCreateRestaurantArgs = {
  input: CreateRestaurantInput;
};


export type MutationCreateSpecialDaysArgs = {
  input?: Maybe<Array<Maybe<CreateSpecialDayInput>>>;
  restaurant: RestaurantBelongsTo;
};


export type MutationCreateTableArgs = {
  input: CreateTableInput;
};


export type MutationDeactivateConsumerArgs = {
  id: Scalars['UUID'];
};


export type MutationDeactivateOwnerArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteConsumerArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteFcmTokenArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteLogoArgs = {
  input: DeleteLogoInput;
};


export type MutationDeleteMenuArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteOwnerArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteRestaurantPhotoArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteSpecialDayArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteTableArgs = {
  input: DeleteTableInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationForwardGeocodeArgs = {
  input?: Maybe<ForwardGeocodeInput>;
};


export type MutationLoginArgs = {
  input?: Maybe<LoginInput>;
};


export type MutationMenuMultipleUploadArgs = {
  input: CreateMenuInput;
};


export type MutationReactivateConsumerArgs = {
  id: Scalars['UUID'];
};


export type MutationReactivateOwnerArgs = {
  id: Scalars['UUID'];
};


export type MutationRegisterConsumerArgs = {
  input?: Maybe<RegisterConsumerInput>;
};


export type MutationRegisterOwnerArgs = {
  input?: Maybe<RegisterOwnerInput>;
};


export type MutationRejectClaimTableArgs = {
  input: RejectClaimTableInput;
};


export type MutationResendEmailVerificationArgs = {
  input: ResendEmailVerificationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRestaurantPhotoMultipleUploadArgs = {
  input: CreateRestaurantPhotoInput;
};


export type MutationReverseGeocodeArgs = {
  input?: Maybe<ReverseGeocodeInput>;
};


export type MutationUpdateConsumerArgs = {
  id: Scalars['UUID'];
  input: ConsumerInput;
};


export type MutationUpdateOwnerArgs = {
  id: Scalars['UUID'];
  input: OwnerInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdatePreferredLocaleArgs = {
  input: UpdatePreferredLocaleInput;
};


export type MutationUpdateRestaurantArgs = {
  input: UpdateRestaurantInput;
};


export type MutationUploadLogoArgs = {
  input: UploadLogoInput;
};


export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type Notification = {
  __typename?: 'Notification';
  consumer: Consumer;
  distance?: Maybe<Distance>;
  id: Scalars['UUID'];
  numOfPersons: Scalars['Int'];
  restaurant: Restaurant;
  status?: Maybe<Scalars['String']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

export type Owner = {
  __typename?: 'Owner';
  country: Country;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  fcmTokens: Array<Maybe<FcmToken>>;
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  restaurant: Restaurant;
  updatedAt: Scalars['DateTime'];
};

export type OwnerBelongsTo = {
  connect: Scalars['UUID'];
};

export type OwnerInput = {
  country: CountryBelongsTo;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  apiVersion: Scalars['String'];
  availableTables?: Maybe<Array<Maybe<AvailableTables>>>;
  businessHours?: Maybe<Array<Maybe<BusinessHour>>>;
  categories: Array<Category>;
  consumerClaims?: Maybe<TablesPaginator>;
  consumerNotifications?: Maybe<Array<Maybe<Notification>>>;
  countries: Array<Country>;
  favouriteRestaurants?: Maybe<Array<Maybe<Restaurant>>>;
  me?: Maybe<Owner>;
  ratings: Array<Rating>;
  restaurantById?: Maybe<Restaurant>;
  restaurantClaims?: Maybe<TablesPaginator>;
  restaurants?: Maybe<RestaurantsPaginator>;
  specialDayById?: Maybe<SpecialDay>;
  tokens?: Maybe<Array<Maybe<Restaurant>>>;
};


export type QueryAvailableTablesArgs = {
  restaurantId: Scalars['UUID'];
};


export type QueryBusinessHoursArgs = {
  input?: Maybe<BusinessHoursInput>;
};


export type QueryConsumerClaimsArgs = {
  input: ConsumerClaimsInput;
};


export type QueryConsumerNotificationsArgs = {
  input: ConsumerNotificationsInput;
};


export type QueryFavouriteRestaurantsArgs = {
  id: Scalars['UUID'];
};


export type QueryRatingsArgs = {
  input: RatingInput;
};


export type QueryRestaurantByIdArgs = {
  input: RestaurantByIdInput;
};


export type QueryRestaurantClaimsArgs = {
  input: RestaurantClaimsInput;
};


export type QueryRestaurantsArgs = {
  input: RestaurantsInput;
};


export type QuerySpecialDayByIdArgs = {
  id: Scalars['UUID'];
};


export type QueryTokensArgs = {
  user_id: Scalars['UUID'];
};

export type Rating = {
  __typename?: 'Rating';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  rating: Scalars['Int'];
  restaurant: Restaurant;
  updatedAt: Scalars['DateTime'];
};

export type RatingBelongsTo = {
  connect: Scalars['ID'];
};

export type RatingInput = {
  restaurant: RestaurantBelongsTo;
};

export type RegisterConsumerInput = {
  country: CountryBelongsTo;
  email: Scalars['String'];
  fcm_token?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type RegisterInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  verification_url?: Maybe<VerificationUrlInput>;
};

export type RegisterOwnerInput = {
  country: CountryBelongsTo;
  email: Scalars['String'];
  fcm_token?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  phone: Scalars['String'];
  verification_url?: Maybe<VerificationUrlInput>;
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  fcmToken?: Maybe<Scalars['String']>;
  status: RegisterStatus;
  token: Scalars['String'];
  user: User;
};

export enum RegisterStatus {
  /** MUST_VERIFY_EMAIL */
  MustVerifyEmail = 'MUST_VERIFY_EMAIL',
  /** SUCCESS */
  Success = 'SUCCESS'
}

export type RejectClaimTableInput = {
  owner: OwnerBelongsTo;
  table: TableBelongsTo;
};

export type ResendEmailVerificationInput = {
  email: Scalars['String'];
  verification_url?: Maybe<VerificationUrlInput>;
};

export type ResendEmailVerificationResponse = {
  __typename?: 'ResendEmailVerificationResponse';
  status: ResendEmailVerificationStatus;
};

export enum ResendEmailVerificationStatus {
  /** EMAIL_SENT */
  EmailSent = 'EMAIL_SENT'
}

export type ResetPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: ResetPasswordStatus;
};

export enum ResetPasswordStatus {
  /** PASSWORD_RESET */
  PasswordReset = 'PASSWORD_RESET'
}

/**
 * The url used to reset the password.
 * Use the `__EMAIL__` and `__TOKEN__` placeholders to inject the reset password email and token.
 *
 * e.g; `https://my-front-end.com?reset-password?email=__EMAIL__&token=__TOKEN__`
 */
export type ResetPasswordUrlInput = {
  url: Scalars['String'];
};

export type Restaurant = {
  __typename?: 'Restaurant';
  active: Scalars['Boolean'];
    address: Scalars['String'];
    averageRating: Scalars['Float'];
    businessHours: Array<BusinessHour>;
    categories: Array<Category>;
    cityName: Scalars['String'];
    consumers: Array<Consumer>;
    countRating: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    distance?: Maybe<Distance>;
    emailVerifiedAt?: Maybe<Scalars['DateTime']>;
    extraInfo?: Maybe<Translatable>;
    id: Scalars['UUID'];
    lat: Scalars['String'];
    lng: Scalars['String'];
    logo?: Maybe<Scalars['Url']>;
    menus: Array<Menu>;
    minArrivingTime: Scalars['Int'];
    name: Scalars['String'];
    notifications: Array<Notification>;
    owner: Owner;
    phone: Scalars['String'];
  phoneVerifiedAt?: Maybe<Scalars['DateTime']>;
  postalCode: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  ratings: Array<Rating>;
  restaurantPhotos: Array<RestaurantPhoto>;
  specialDays: Array<SpecialDay>;
  status?: Maybe<Scalars['String']>;
  streetName: Scalars['String'];
  streetNumber: Scalars['String'];
  tables: Array<Table>;
  updatedAt: Scalars['DateTime'];
};


export type RestaurantLogoArgs = {
  variant?: Maybe<RestaurantLogoVariant>;
};

export type RestaurantBelongsTo = {
  connect: Scalars['UUID'];
};

export type RestaurantByIdInput = {
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
  countryId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  numOfPersons: Scalars['Int'];
};

export type RestaurantClaimsInput = {
  date?: Maybe<Scalars['Date']>;
  first: Scalars['Int'];
  page: Scalars['Int'];
  restaurantId: Scalars['UUID'];
  type: Claim_Type;
};

/** Allowed resizes for the `variant` argument on the query `logo`. */
export enum RestaurantLogoVariant {
  Big = 'BIG',
  Medium = 'MEDIUM',
  Original = 'ORIGINAL',
  Small = 'SMALL'
}

export type RestaurantPhoto = {
  __typename?: 'RestaurantPhoto';
  createdAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  image?: Maybe<Scalars['Url']>;
  priority?: Maybe<Scalars['Int']>;
  restaurant: Restaurant;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type RestaurantPhotoImageArgs = {
  variant?: Maybe<RestaurantPhotoImageVariant>;
};

/** Allowed resizes for the `variant` argument on the query `image`. */
export enum RestaurantPhotoImageVariant {
  Big = 'BIG',
  Medium = 'MEDIUM',
  Original = 'ORIGINAL',
  Small = 'SMALL'
}

export type RestaurantsBelongsToMany = {
  connect?: Maybe<Array<Scalars['UUID']>>;
};

export type RestaurantsInput = {
  address?: Maybe<Scalars['String']>;
  coordinates?: Maybe<CoordinatesInput>;
  countryId?: Maybe<Scalars['UUID']>;
  first: Scalars['Int'];
  numOfPersons: Scalars['Int'];
  page: Scalars['Int'];
  radius: Scalars['Int'];
};

export type RestaurantsPaginator = {
  __typename?: 'RestaurantsPaginator';
  data: Array<Restaurant>;
  paginatorInfo: PaginatorInfo;
};

export type Result = {
  __typename?: 'Result';
  message: Scalars['String'];
};

export type ReverseGeocode = {
  __typename?: 'ReverseGeocode';
  address?: Maybe<Scalars['String']>;
};

export type ReverseGeocodeInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type SpecialDay = {
  __typename?: 'SpecialDay';
  closeTime?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isClosed?: Maybe<Scalars['Boolean']>;
  openTime?: Maybe<Scalars['String']>;
  specialDayEndDate?: Maybe<Scalars['Date']>;
  specialDayName?: Maybe<Scalars['String']>;
  specialDayStartDate?: Maybe<Scalars['Date']>;
};

export type SpecialDayInput = {
  restaurant: RestaurantBelongsTo;
};

export type Table = {
  __typename?: 'Table';
  claimedAt?: Maybe<Scalars['DateTime']>;
  claimedBy?: Maybe<Consumer>;
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  id: Scalars['UUID'];
  numOfPersons: Scalars['Int'];
  restaurant: Restaurant;
  status: Scalars['String'];
  type: TableTypeEnum;
  updatedAt: Scalars['DateTime'];
};

export type TableBelongsTo = {
  connect: Scalars['UUID'];
};

export type TableResponse = Result | Table;

export enum TableTypeEnum {
  /** Inside */
  Inside = 'INSIDE',
  /** Outside */
  Outside = 'OUTSIDE'
}

export type TablesPaginator = {
    __typename?: 'TablesPaginator';
    data?: Maybe<Array<Maybe<Table>>>;
    paginatorInfo?: Maybe<PaginatorInfo>;
};

export type Translatable = {
    __typename?: 'Translatable';
    en?: Maybe<Scalars['String']>;
    nl?: Maybe<Scalars['String']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
    /** Only return trashed results. */
    Only = 'ONLY',
    /** Return both trashed and non-trashed results. */
    With = 'WITH',
    /** Only return non-trashed results. */
    Without = 'WITHOUT'
}

export type UpdateBusinessHoursInput = {
  closeTime?: Maybe<Scalars['String']>;
  dayOfWeek?: Maybe<Scalars['Int']>;
  openTime?: Maybe<Scalars['String']>;
};

export type UpdatePasswordInput = {
  password: Scalars['String'];
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: UpdatePasswordStatus;
};

export enum UpdatePasswordStatus {
  /** PASSWORD_UPDATED */
  PasswordUpdated = 'PASSWORD_UPDATED'
}

export type UpdatePreferredLocaleInput = {
  locale: Scalars['String'];
  userId: Scalars['UUID'];
};

export type UpdateRestaurantInput = {
    businessHours?: Maybe<Array<Maybe<UpdateBusinessHoursInput>>>;
    category?: Maybe<CategoryBelongsTo>;
    cityName?: Maybe<Scalars['String']>;
    coordinates?: Maybe<CoordinatesInput>;
    extraInfo?: Maybe<ExtraInfoTranslations>;
    logo?: Maybe<Scalars['Upload']>;
    menus?: Maybe<Array<Maybe<Scalars['Upload']>>>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    photos?: Maybe<Array<Maybe<Scalars['Upload']>>>;
    postalCode?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Int']>;
    restaurant: RestaurantBelongsTo;
    streetName?: Maybe<Scalars['String']>;
    streetNumber?: Maybe<Scalars['String']>;
};

export type UploadLogoInput = {
  logo: Scalars['Upload'];
  restaurant: RestaurantBelongsTo;
};

export type User = {
  __typename?: 'User';
  country?: Maybe<Country>;
  email: Scalars['String'];
  fcmTokens: Array<Maybe<FcmToken>>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  locale?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  restaurant?: Maybe<Restaurant>;
};

export type UserBelongsTo = {
  connect: Scalars['UUID'];
};

/**
 * The url used to verify the email address.
 * Use __ID__ and __HASH__ to inject values.
 *
 * e.g; `https://my-front-end.com/verify-email?id=__ID__&hash=__HASH__`
 *
 * If the API uses signed email verification urls
 * you must also use __EXPIRES__ and __SIGNATURE__
 *
 * e.g; `https://my-front-end.com/verify-email?id=__ID__&hash=__HASH__&expires=__EXPIRES__&signature=__SIGNATURE__`
 */
export type VerificationUrlInput = {
    url: Scalars['String'];
};

export type VerifyEmailInput = {
    expires?: Maybe<Scalars['Int']>;
    hash: Scalars['String'];
    id: Scalars['ID'];
    signature?: Maybe<Scalars['String']>;
};

export type ExtraInfoTranslations = {
    en?: Maybe<Scalars['String']>;
    nl?: Maybe<Scalars['String']>;
};

export const PaginatorInfoFragmentDoc = gql`
    fragment paginatorInfo on PaginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
  total
}
    `;
export const RestaurantFragmentDoc = gql`
    fragment restaurant on Restaurant {
  id
  active
  name
  address
  postalCode
  phone
  price
  distance {
    unit
    value
  }
  active
        minArrivingTime
        logo
        lat
        lng
        createdAt
        updatedAt
        averageRating
        phoneVerifiedAt
        emailVerifiedAt
        status
        extraInfo {
            nl
            en
        }
        businessHours {
            id
            closeTime
            dayOfWeek
            openTime
            __typename
        }
        tables {
            numOfPersons
            status
    claimedAt
    updatedAt
  }
  menus {
    attachment(variant: SMALL)
  }
  restaurantPhotos {
    image(variant: SMALL)
  }
  categories {
    id
    createdAt
    name
    updatedAt
  }
}
    `;
export const ClaimedByFragmentDoc = gql`
    fragment claimedBy on Consumer {
  id
  firstName
  lastName
  __typename
  updatedAt
}
    `;
export const TableFragmentDoc = gql`
    fragment table on Table {
  id
  type
  numOfPersons
  claimedBy {
    ...claimedBy
  }
  claimedAt
  createdAt
  status
  __typename
}
    ${ClaimedByFragmentDoc}`;
export const ApiVersionDocument = gql`
    query apiVersion {
  apiVersion
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApiVersionQueryService extends Apollo.Query<ApiVersionQuery, ApiVersionQueryVariables> {
    document = ApiVersionDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CountriesDocument = gql`
    query countries {
  countries {
    id
    code
    name
    fullName
    currencySymbol
    timezone
    active
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CountriesQueryService extends Apollo.Query<CountriesQuery, CountriesQueryVariables> {
    document = CountriesDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterConsumerDocument = gql`
    mutation registerConsumer($input: RegisterConsumerInput!) {
  registerConsumer(input: $input) {
    token
    user {
      id
      locale
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterConsumerMutationService extends Apollo.Mutation<RegisterConsumerMutation, RegisterConsumerMutationVariables> {
    document = RegisterConsumerDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateConsumerDocument = gql`
    mutation updateConsumer($input: ConsumerInput!, $id: UUID!) {
  updateConsumer(id: $id, input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateConsumerMutationService extends Apollo.Mutation<UpdateConsumerMutation, UpdateConsumerMutationVariables> {
    document = UpdateConsumerDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteConsumerDocument = gql`
    mutation deleteConsumer($id: UUID!) {
  deleteConsumer(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteConsumerMutationService extends Apollo.Mutation<DeleteConsumerMutation, DeleteConsumerMutationVariables> {
    document = DeleteConsumerDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReverseGeocodeDocument = gql`
    mutation reverseGeocode($input: ReverseGeocodeInput!) {
  reverseGeocode(input: $input) {
    address
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReverseGeocodeMutationService extends Apollo.Mutation<ReverseGeocodeMutation, ReverseGeocodeMutationVariables> {
    document = ReverseGeocodeDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForwardGeocodeDocument = gql`
    mutation forwardGeocode($input: ForwardGeocodeInput!) {
  forwardGeocode(input: $input) {
    latitude
    longitude
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ForwardGeocodeMutationService extends Apollo.Mutation<ForwardGeocodeMutation, ForwardGeocodeMutationVariables> {
    document = ForwardGeocodeDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestaurantsDocument = gql`
    query restaurants($input: RestaurantsInput!) {
  restaurants(input: $input) {
    data {
      ...restaurant
    }
    paginatorInfo {
      ...paginatorInfo
    }
  }
}
    ${RestaurantFragmentDoc}
${PaginatorInfoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RestaurantsQueryService extends Apollo.Query<RestaurantsQuery, RestaurantsQueryVariables> {
    document = RestaurantsDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestaurantByIdDocument = gql`
    query restaurantById($input: RestaurantByIdInput!) {
  restaurantById(input: $input) {
    ...restaurant
  }
}
    ${RestaurantFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RestaurantByIdQueryService extends Apollo.Query<RestaurantByIdQuery, RestaurantByIdQueryVariables> {
    document = RestaurantByIdDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClaimTableDocument = gql`
    mutation claimTable($input: ClaimTableInput!) {
  claimTable(input: $input) {
    ... on Table {
      ...table
    }
    ... on Result {
      message
    }
  }
}
    ${TableFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ClaimTableMutationService extends Apollo.Mutation<ClaimTableMutation, ClaimTableMutationVariables> {
    document = ClaimTableDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateNotificationDocument = gql`
    mutation createNotification($input: CreateNotificationInput!) {
  createNotification(input: $input) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateNotificationMutationService extends Apollo.Mutation<CreateNotificationMutation, CreateNotificationMutationVariables> {
    document = CreateNotificationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: UUID!) {
  deleteNotification(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteNotificationMutationService extends Apollo.Mutation<DeleteNotificationMutation, DeleteNotificationMutationVariables> {
    document = DeleteNotificationDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelClaimTableDocument = gql`
    mutation cancelClaimTable($input: CancelClaimTableInput!) {
  cancelClaimTable(input: $input) {
    __typename
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelClaimTableMutationService extends Apollo.Mutation<CancelClaimTableMutation, CancelClaimTableMutationVariables> {
    document = CancelClaimTableDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentConsumerClaimsDocument = gql`
    query currentConsumerClaims($input: ConsumerClaimsInput!) {
  consumerClaims(input: $input) {
    data {
      ...table
      restaurant {
        ...restaurant
      }
    }
  }
}
    ${TableFragmentDoc}
${RestaurantFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentConsumerClaimsQueryService extends Apollo.Query<CurrentConsumerClaimsQuery, CurrentConsumerClaimsQueryVariables> {
    document = CurrentConsumerClaimsDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllConsumerClaimsDocument = gql`
    query allConsumerClaims($input: ConsumerClaimsInput!) {
  consumerClaims(input: $input) {
    data {
      ...table
    }
  }
}
    ${TableFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AllConsumerClaimsQueryService extends Apollo.Query<AllConsumerClaimsQuery, AllConsumerClaimsQueryVariables> {
    document = AllConsumerClaimsDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConsumerNotificationsDocument = gql`
    query consumerNotifications($input: ConsumerNotificationsInput!) {
  consumerNotifications(input: $input) {
    status
    numOfPersons
    distance {
      unit
      value
    }
    id
    restaurant {
      ...restaurant
    }
  }
}
    ${RestaurantFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConsumerNotificationsQueryService extends Apollo.Query<ConsumerNotificationsQuery, ConsumerNotificationsQueryVariables> {
    document = ConsumerNotificationsDocument;

    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export type ApiVersionQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiVersionQuery = { __typename?: 'Query', apiVersion: string };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'Country', id: any, code: string, name: string, fullName: string, currencySymbol: string, timezone: string, active: boolean }> };

export type RegisterConsumerMutationVariables = Exact<{
  input: RegisterConsumerInput;
}>;


export type RegisterConsumerMutation = { __typename?: 'Mutation', registerConsumer: { __typename?: 'RegisterResponse', token: string, user: { __typename?: 'User', id: string, locale?: Maybe<string> } } };

export type UpdateConsumerMutationVariables = Exact<{
  input: ConsumerInput;
  id: Scalars['UUID'];
}>;


export type UpdateConsumerMutation = { __typename?: 'Mutation', updateConsumer: { __typename?: 'Consumer', id: any } };

export type DeleteConsumerMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteConsumerMutation = { __typename?: 'Mutation', deleteConsumer?: Maybe<{ __typename?: 'Consumer', id: any }> };

export type ReverseGeocodeMutationVariables = Exact<{
  input: ReverseGeocodeInput;
}>;


export type ReverseGeocodeMutation = { __typename?: 'Mutation', reverseGeocode: { __typename?: 'ReverseGeocode', address?: Maybe<string> } };

export type ForwardGeocodeMutationVariables = Exact<{
  input: ForwardGeocodeInput;
}>;


export type ForwardGeocodeMutation = { __typename?: 'Mutation', forwardGeocode: { __typename?: 'Geocode', latitude?: Maybe<number>, longitude?: Maybe<number> } };

export type RestaurantsQueryVariables = Exact<{
  input: RestaurantsInput;
}>;


export type RestaurantsQuery = {
    __typename?: 'Query', restaurants?: Maybe<{
        __typename?: 'RestaurantsPaginator', data: Array<{
            __typename?: 'Restaurant', id: any, active: boolean, name: string, address: string, postalCode: string, phone: string, price?: Maybe<number>, minArrivingTime: number, logo?: Maybe<any>, lat: string, lng: string, createdAt: any, updatedAt: any, averageRating: number, phoneVerifiedAt?: Maybe<any>, emailVerifiedAt?: Maybe<any>, status?: Maybe<string>, distance?: Maybe<{ __typename?: 'Distance', unit: string, value: number }>, extraInfo?: Maybe<{ __typename?: 'Translatable', nl?: Maybe<string>, en?: Maybe<string> }>, businessHours: Array<{ __typename: 'BusinessHour', id: string, closeTime?: Maybe<string>, dayOfWeek?: Maybe<number>, openTime?: Maybe<string> }>, tables: Array<{ __typename?: 'Table', numOfPersons: number, status: string, claimedAt?: Maybe<any>, updatedAt: any }>, menus: Array<{ __typename?: 'Menu', attachment?: Maybe<any> }>, restaurantPhotos: Array<{ __typename?: 'RestaurantPhoto', image?: Maybe<any> }>, categories: Array<{ __typename?: 'Category', id: any, createdAt: any, name?: Maybe<string>, updatedAt: any }>
        }>, paginatorInfo: { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: Maybe<number>, hasMorePages: boolean, lastItem?: Maybe<number>, lastPage: number, perPage: number, total: number }
    }>
};

export type RestaurantByIdQueryVariables = Exact<{
  input: RestaurantByIdInput;
}>;


export type RestaurantByIdQuery = {
    __typename?: 'Query', restaurantById?: Maybe<{
        __typename?: 'Restaurant', id: any, active: boolean, name: string, address: string, postalCode: string, phone: string, price?: Maybe<number>, minArrivingTime: number, logo?: Maybe<any>, lat: string, lng: string, createdAt: any, updatedAt: any, averageRating: number, phoneVerifiedAt?: Maybe<any>, emailVerifiedAt?: Maybe<any>, status?: Maybe<string>, distance?: Maybe<{ __typename?: 'Distance', unit: string, value: number }>, extraInfo?: Maybe<{ __typename?: 'Translatable', nl?: Maybe<string>, en?: Maybe<string> }>, businessHours: Array<{ __typename: 'BusinessHour', id: string, closeTime?: Maybe<string>, dayOfWeek?: Maybe<number>, openTime?: Maybe<string> }>, tables: Array<{ __typename?: 'Table', numOfPersons: number, status: string, claimedAt?: Maybe<any>, updatedAt: any }>, menus: Array<{ __typename?: 'Menu', attachment?: Maybe<any> }>, restaurantPhotos: Array<{ __typename?: 'RestaurantPhoto', image?: Maybe<any> }>, categories: Array<{ __typename?: 'Category', id: any, createdAt: any, name?: Maybe<string>, updatedAt: any }>
    }>
};

export type PaginatorInfoFragment = { __typename?: 'PaginatorInfo', count: number, currentPage: number, firstItem?: Maybe<number>, hasMorePages: boolean, lastItem?: Maybe<number>, lastPage: number, perPage: number, total: number };

export type RestaurantFragment = {
    __typename?: 'Restaurant', id: any, active: boolean, name: string, address: string, postalCode: string, phone: string, price?: Maybe<number>, minArrivingTime: number, logo?: Maybe<any>, lat: string, lng: string, createdAt: any, updatedAt: any, averageRating: number, phoneVerifiedAt?: Maybe<any>, emailVerifiedAt?: Maybe<any>, status?: Maybe<string>, distance?: Maybe<{ __typename?: 'Distance', unit: string, value: number }>, extraInfo?: Maybe<{ __typename?: 'Translatable', nl?: Maybe<string>, en?: Maybe<string> }>, businessHours: Array<{ __typename: 'BusinessHour', id: string, closeTime?: Maybe<string>, dayOfWeek?: Maybe<number>, openTime?: Maybe<string> }>, tables: Array<{ __typename?: 'Table', numOfPersons: number, status: string, claimedAt?: Maybe<any>, updatedAt: any }>, menus: Array<{ __typename?: 'Menu', attachment?: Maybe<any> }>, restaurantPhotos: Array<{ __typename?: 'RestaurantPhoto', image?: Maybe<any> }>, categories: Array<{ __typename?: 'Category', id: any, createdAt: any, name?: Maybe<string>, updatedAt: any }>
};

export type ClaimTableMutationVariables = Exact<{
  input: ClaimTableInput;
}>;


export type ClaimTableMutation = { __typename?: 'Mutation', claimTable?: Maybe<{ __typename?: 'Result', message: string } | { __typename: 'Table', id: any, type: TableTypeEnum, numOfPersons: number, claimedAt?: Maybe<any>, createdAt: any, status: string, claimedBy?: Maybe<{ __typename: 'Consumer', id: any, firstName: string, lastName: string, updatedAt: any }> }> };

export type CreateNotificationMutationVariables = Exact<{
  input: CreateNotificationInput;
}>;


export type CreateNotificationMutation = { __typename?: 'Mutation', createNotification: { __typename?: 'Notification', status?: Maybe<string> } };

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteNotificationMutation = { __typename?: 'Mutation', deleteNotification?: Maybe<{ __typename?: 'Notification', id: any }> };

export type CancelClaimTableMutationVariables = Exact<{
  input: CancelClaimTableInput;
}>;


export type CancelClaimTableMutation = { __typename?: 'Mutation', cancelClaimTable?: Maybe<{ __typename: 'Result' } | { __typename: 'Table' }> };

export type CurrentConsumerClaimsQueryVariables = Exact<{
  input: ConsumerClaimsInput;
}>;


export type CurrentConsumerClaimsQuery = {
    __typename?: 'Query', consumerClaims?: Maybe<{
        __typename?: 'TablesPaginator', data?: Maybe<Array<Maybe<{
            __typename: 'Table', id: any, type: TableTypeEnum, numOfPersons: number, claimedAt?: Maybe<any>, createdAt: any, status: string, restaurant: {
                __typename?: 'Restaurant', id: any, active: boolean, name: string, address: string, postalCode: string, phone: string, price?: Maybe<number>, minArrivingTime: number, logo?: Maybe<any>, lat: string, lng: string, createdAt: any, updatedAt: any, averageRating: number, phoneVerifiedAt?: Maybe<any>, emailVerifiedAt?: Maybe<any>, status?: Maybe<string>, distance?: Maybe<{ __typename?: 'Distance', unit: string, value: number }>, extraInfo?: Maybe<{ __typename?: 'Translatable', nl?: Maybe<string>, en?: Maybe<string> }>, businessHours: Array<{ __typename: 'BusinessHour', id: string, closeTime?: Maybe<string>, dayOfWeek?: Maybe<number>, openTime?: Maybe<string> }>, tables: Array<{ __typename?: 'Table', numOfPersons: number, status: string, claimedAt?: Maybe<any>, updatedAt: any }>, menus: Array<{ __typename?: 'Menu', attachment?: Maybe<any> }>, restaurantPhotos: Array<{ __typename?: 'RestaurantPhoto', image?: Maybe<any> }>, categories: Array<{ __typename?: 'Category', id: any, createdAt: any, name?: Maybe<string>, updatedAt: any }>
            }, claimedBy?: Maybe<{ __typename: 'Consumer', id: any, firstName: string, lastName: string, updatedAt: any }>
        }>>>
    }>
};

export type AllConsumerClaimsQueryVariables = Exact<{
  input: ConsumerClaimsInput;
}>;


export type AllConsumerClaimsQuery = { __typename?: 'Query', consumerClaims?: Maybe<{ __typename?: 'TablesPaginator', data?: Maybe<Array<Maybe<{ __typename: 'Table', id: any, type: TableTypeEnum, numOfPersons: number, claimedAt?: Maybe<any>, createdAt: any, status: string, claimedBy?: Maybe<{ __typename: 'Consumer', id: any, firstName: string, lastName: string, updatedAt: any }> }>>> }> };

export type ConsumerNotificationsQueryVariables = Exact<{
  input: ConsumerNotificationsInput;
}>;


export type ConsumerNotificationsQuery = {
    __typename?: 'Query', consumerNotifications?: Maybe<Array<Maybe<{
        __typename?: 'Notification', status?: Maybe<string>, numOfPersons: number, id: any, distance?: Maybe<{ __typename?: 'Distance', unit: string, value: number }>, restaurant: {
            __typename?: 'Restaurant', id: any, active: boolean, name: string, address: string, postalCode: string, phone: string, price?: Maybe<number>, minArrivingTime: number, logo?: Maybe<any>, lat: string, lng: string, createdAt: any, updatedAt: any, averageRating: number, phoneVerifiedAt?: Maybe<any>, emailVerifiedAt?: Maybe<any>, status?: Maybe<string>, distance?: Maybe<{ __typename?: 'Distance', unit: string, value: number }>, extraInfo?: Maybe<{ __typename?: 'Translatable', nl?: Maybe<string>, en?: Maybe<string> }>, businessHours: Array<{ __typename: 'BusinessHour', id: string, closeTime?: Maybe<string>, dayOfWeek?: Maybe<number>, openTime?: Maybe<string> }>, tables: Array<{ __typename?: 'Table', numOfPersons: number, status: string, claimedAt?: Maybe<any>, updatedAt: any }>, menus: Array<{ __typename?: 'Menu', attachment?: Maybe<any> }>, restaurantPhotos: Array<{ __typename?: 'RestaurantPhoto', image?: Maybe<any> }>, categories: Array<{ __typename?: 'Category', id: any, createdAt: any, name?: Maybe<string>, updatedAt: any }>
        }
    }>>>
};

export type TableFragment = { __typename: 'Table', id: any, type: TableTypeEnum, numOfPersons: number, claimedAt?: Maybe<any>, createdAt: any, status: string, claimedBy?: Maybe<{ __typename: 'Consumer', id: any, firstName: string, lastName: string, updatedAt: any }> };

export type ClaimedByFragment = { __typename: 'Consumer', id: any, firstName: string, lastName: string, updatedAt: any };
