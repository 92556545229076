import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { ListingService } from 'src/app/services/listing.service';
import { Restaurant } from '../../../generated/graphql';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConsumerService } from '../../../services/consumer.service';
import { TablesService } from '../../../services/tables.service';
import { GeocodingService } from '../../../services/geocoding.service';
import { Marker, marker } from 'leaflet';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-c-overview-map',
    templateUrl: './c-overview-map.component.html',
    styleUrls: ['./c-overview-map.component.scss'],
})
export class COverviewMapComponent implements AfterViewInit, OnDestroy {
    public selectedEstablishment$: BehaviorSubject<Restaurant | null> = new BehaviorSubject(null);
    public isLoading$ = this.listingService.listingIsLoading$;
    public registrationPopupOpen = false;

    private selectedEstablishmentId: string;
    private map;
    // @ts-ignore
    private markers = L.markerClusterGroup(); // MarkerClusterGroup don't have Types
    // @ts-ignore
    private currentLocationMarkers = L.markerClusterGroup();

    private tableUnclaimedIcon = L.icon({
        iconUrl: '/assets/images/svg/icon-table-white.svg',
        className: 'map-marker map-marker--unclaimed',
        iconSize: [36, 36], // size of the icon
    });

    private subscriptions$ = new Subscription();

    private currentLocationIcon = L.icon({
        iconUrl: '/assets/images/svg/icon-location-pin-white.svg',
        className: 'map-marker map-marker--current',
        iconSize: [36, 36], // size of the icon
    });

    private tableClaimedIcon = L.icon({
        iconUrl: '/assets/images/svg/icon-table-white.svg',
        className: 'map-marker map-marker--claimed',
        iconSize: [36, 36], // size of the icon
    });

    private tableWaitingIcon = L.icon({
        iconUrl: '/assets/images/svg/icon-clock-white.svg',
        className: 'map-marker map-marker--waiting',
        iconSize: [36, 36], // size of the icon
    });

    private ownLat: number;
    private ownLon: number;

    constructor(
        private listingService: ListingService,
        private consumerService: ConsumerService,
        private tablesService: TablesService,
        private geocodingService: GeocodingService,
        private router: Router,
        private messageService: MessageService,
        public translate: TranslateService
    ) {
    }

    public ngAfterViewInit(): void {

        setTimeout(() => {
            this.initMap();
        }, 500);
    }

    public ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    public handleClaims(event) {
        // Check if user has a token, which basically means he has an account
        if (this.consumerService.state.token.value) {
            this.runClaim(event);
        } else {
            // if not open up the registration modal
            this.registrationPopupOpen = true;
            this.consumerService.state.token.subscribe((token) => {
                // Registration complete? run the claim process
                if (token) {
                    this.runClaim(event);
                    this.registrationPopupOpen = false;
                }
            });
        }
    }

    private initMap(): void {
        this.geocodingService.getCoordsFromAddress(this.listingService.listingFilterValues.getValue().searchTerm).then((res) => {

            this.ownLat = res.data.forwardGeocode.latitude;
            this.ownLon = res.data.forwardGeocode.longitude;

            this.map = L.map('map', {
                center: [this.ownLat, this.ownLon],
                zoom: 9
            });
            // eslint-disable-next-line no-underscore-dangle
            this.map._layersMaxZoom = 19;

            const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                minZoom: 3,
            });
            this.createMapMarkers();
            tiles.addTo(this.map);

            // Attribution options
            const attrOptions = {
                prefix: 'Freetable'
            };

            // Creating an attribution
            const attr = L.control.attribution(attrOptions);
            attr.addTo(this.map);  // Adding attribution to the map
        });
    }

    private deleteMapMarkers() {
        this.map.removeLayer(this.markers);
        this.map.removeLayer(this.currentLocationMarkers);
        // @ts-ignore
        this.markers = L.markerClusterGroup(); // MarkerClusterGroup don't have Types
        // @ts-ignore
        this.currentLocationMarkers = L.markerClusterGroup(); // MarkerClusterGroup don't have Types
    }

    private createMapMarkers() {
        this.subscriptions$.add(this.listingService.state.restaurants.subscribe((restaurants) => {
            if (!restaurants) {
                return;
            }
            this.deleteMapMarkers();
            this.currentLocationMarkers.addLayer(L.marker(
                [
                    Number(this.ownLat),
                    Number(this.ownLon)
                ], {icon: this.currentLocationIcon, title: 'test title'}));
            restaurants.data.restaurants.data.forEach((establishment) => {
                if (establishment.status === 'claimable') {
                    this.markers.addLayer(L.marker(
                        [
                            Number(establishment.lat),
                            Number(establishment.lng)
                        ], {icon: this.tableUnclaimedIcon, title: establishment.id}));
                } else {
                    this.markers.addLayer(L.marker(
                        [
                            Number(establishment.lat),
                            Number(establishment.lng)
                        ], {icon: this.tableWaitingIcon, title: establishment.id}));
                }
            });
            this.markers.on('click', this.handleMarkerClick.bind(this));
            this.map.addLayer(this.markers);
            this.map.addLayer(this.currentLocationMarkers);
            // @ts-ignore
            this.map.setView(new L.latLng(this.ownLat, this.ownLon), 12);
        }));
    }

    private handleMarkerClick(event) {
        console.log('handleMarkerClick', event);
        const selectId = event.sourceTarget.options.title;
        this.subscriptions$.add(this.listingService.state.restaurants.subscribe((res) => {
            const found = res.data.restaurants.data.filter((establishment) => establishment.id === selectId);
            if (found[0]) {
                this.selectedEstablishment$.next(found[0] as Restaurant);
            }
        }));
    }

    private runClaim(event) {
        if (event.status === 'claimable') {
            this.tablesService.claimTable(event).then(() => {
                this.router.navigate(['my-tables']);
            }).catch((err) => {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translate.instant('errors.error'),
                    detail: err
                });
            });
        }
    }
}
