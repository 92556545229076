import { Injectable } from '@angular/core';
import {
    Restaurant,
    RestaurantByIdQuery,
    RestaurantByIdQueryService,
    RestaurantsQuery,
    RestaurantsQueryService
} from '../generated/graphql';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { Globals } from '../config/globals';

@Injectable({
    providedIn: 'root'
})
export class ListingService {

    public navigatorValues = {
        page: new BehaviorSubject(1) as BehaviorSubject<number>
    };

    public listingFilterValues = new BehaviorSubject({
        isDefault: true,
        searchTerm: 'Haarlem',
        numOfPersons: 2,
        radius: 4,
        latitude: Number(Globals.config.LAT_NL),
        longitude: Number(Globals.config.LON_NL),
    });

    public hasBeenGeocoded = new BehaviorSubject<boolean>(false);

    public listingIsLoading$ = new BehaviorSubject(true);

    public state = {
        restaurants: new BehaviorSubject(null) as BehaviorSubject<ApolloQueryResult<RestaurantsQuery>>,
        selectedEstablishment: new BehaviorSubject(null) as BehaviorSubject<Restaurant>,
    };

    constructor(
        private restaurantsQueryService: RestaurantsQueryService,
        private restaurantByIdQueryService: RestaurantByIdQueryService,
    ) {
        combineLatest([
            this.listingFilterValues,
            this.navigatorValues.page,
        ]).pipe(
            debounceTime(400),
        ).subscribe((combinedObservables) => {
            console.log('listing.service constructor combinedObservables', combinedObservables[0]);
            this.getRestaurants({
                searchTerm: String(combinedObservables[0].searchTerm),
                numOfPersons: Number(combinedObservables[0].numOfPersons),
                radius: Number(combinedObservables[0].radius),
                coordinates: {
                    latitude: Number(combinedObservables[0].latitude),
                    longitude: Number(combinedObservables[0].longitude),
                },
                page: Number(combinedObservables[1]),
            });
        });
    }

    getRestaurantById(input: { id: string }) {
        console.log('getRestaurantById');
        return this.restaurantByIdQueryService.fetch({
            input: {
                id: input.id,
                numOfPersons: Number(this.listingFilterValues.getValue().numOfPersons),
                coordinates: {
                    latitude: Number(Globals.config.LAT_NL),
                    longitude: Number(Globals.config.LON_NL),
                },
            }
        }, {
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                },
            },
        }).toPromise().then((res) => {
            // @ts-ignore
            this.state.selectedEstablishment.next(res.data.restaurantById);
        });
    };

    getRestaurants(
        input: {
            searchTerm: string;
            numOfPersons: number;
            radius: number;
            page: number;
            coordinates: {
                latitude: number;
                longitude: number;
            };
        },
        noLoading: boolean = false
    ) {
        // console.log('getRestaurants input', input);
        if (!noLoading) {
            this.listingIsLoading$.next(true);
        }
        this.restaurantsQueryService.fetch({
            input: {
                coordinates: {
                    latitude: input.coordinates.latitude,
                    longitude: input.coordinates.longitude,
                },
                address: input.searchTerm,
                page: input.page,
                numOfPersons: input.numOfPersons,
                first: 10,
                radius: input.radius,
            }
        }, {
            context: {
                headers: {
                    'Accept-Language': localStorage.getItem('language') || 'nl',
                },
            },
        }).toPromise().then((res) => {
            this.listingIsLoading$.next(false);
            this.state.restaurants.next(res);
        });
    }

    rerunGetRestaurants() {
        console.log('rerunGetRestaurants');
        this.getRestaurants({
            searchTerm: String(this.listingFilterValues.getValue().searchTerm),
            numOfPersons: Number(this.listingFilterValues.getValue().numOfPersons),
            radius: Number(this.listingFilterValues.getValue().radius),
            coordinates: {
                latitude: Number(this.listingFilterValues.getValue().latitude),
                longitude: Number(this.listingFilterValues.getValue().longitude),
            },
            page: Number(this.navigatorValues.page.getValue()),
        }, true);
    }

    priceIndication(price: number) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentSymbol = timeZone.toLowerCase() === 'europe/amsterdam' ? '€' : '£';
        return currentSymbol.repeat(price);
    }
}
