import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-m-loader-fullscreen',
    templateUrl: './m-loader-fullscreen.component.html',
    styleUrls: ['./m-loader-fullscreen.component.scss'],
})
export class MLoaderFullscreenComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
