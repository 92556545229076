import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListingService } from 'src/app/services/listing.service';
import { ReverseGeocodeInput } from '../../../generated/graphql';
import { GeocodingService } from '../../../services/geocoding.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-c-listing-filter',
    templateUrl: './c-listing-filter.component.html',
    styleUrls: ['./c-listing-filter.component.scss'],
})
export class CListingFilterComponent implements OnInit {

    public listingFilterValues$ = this.listingService.listingFilterValues;

    constructor(
        private listingService: ListingService,
        private geocodingService: GeocodingService,
        private router: Router,
        public translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.currentUrlIsListing();
    }

    public currentUrlIsListing() {
        const currentUrl = this.router.routerState.snapshot.url;
        if (currentUrl === '/overview-map') {
            return false;
        }
        if (currentUrl === '/overview') {
            return true;
        }
        console.error('Error getting /overview or /overview-map, Did the url of the overview logic change?');
    }

    public handleFilter(formValues) {
        const newState = {
            ...this.listingService.listingFilterValues.getValue(),
            ...formValues,
            default: false,
        };
        console.log('handleFilter newState', newState);
        this.listingService.listingFilterValues.next(newState);
    }

    public handleGeoLocation(event) {
        const reverseGeocodeInput: ReverseGeocodeInput = {
            latitude: event.coords.latitude,
            longitude: event.coords.longitude,
        };
        this.geocodingService.getAddressFromCoordinates(reverseGeocodeInput).then((response) => {
            const newState = {
                ...this.listingService.listingFilterValues.getValue(),
                ...reverseGeocodeInput,
                isDefault: false,
                searchTerm: response.data.reverseGeocode.address,
            };
            console.log('newState after geoCode', newState);
            this.listingService.listingFilterValues.next(newState);
            this.listingService.hasBeenGeocoded.next(true);
        });
    }

}
