import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-m-paginator',
    templateUrl: './m-paginator.component.html',
    styleUrls: ['./m-paginator.component.scss'],
})
export class MPaginatorComponent {

    @Input() first = 1;
    @Input() rows = 5;
    @Input() totalRecords = 5;
    @Input() showJumpToPageDropdown = 'true';
    @Input() showPageLinks = false;
    @Output() pageChanging = new EventEmitter();

    onPageChange(event) {
        console.log(event);
        this.pageChanging.emit(event);
    }

}
