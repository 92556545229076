import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Table } from '../../../generated/graphql';
import { TablesService } from '../../../services/tables.service';
import { ConsumerService } from '../../../services/consumer.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-c-current-claims',
    templateUrl: './c-current-claims.component.html',
    styleUrls: ['./c-current-claims.component.scss'],
})
export class CCurrentClaimsComponent implements OnInit {

    public tables$: BehaviorSubject<Table[]> = new BehaviorSubject([]);

    constructor(
        private translate: TranslateService,
        private tablesService: TablesService,
        private consumerService: ConsumerService,
        private modalController: ModalController,
    ) {
    }

    public fetchTables() {
        this.tablesService.getCurrentConsumerClaims().then((response) => {
            if (!response) {
                return;
            }
            const claimedTables = [];
            if (response.data.consumerClaims.data
                && Array.isArray(response.data.consumerClaims.data)
            ) {
                response.data.consumerClaims.data.forEach((table) => {
                    claimedTables.push(table);
                });
                if (claimedTables) {
                    this.tables$.next(claimedTables);
                }
                console.log('CCurrentClaimsComponent claimedTables', claimedTables);
            }
        });
    }

    public handleClaims(event: { status: string; tableId: string }) {
        if (event.status === 'pending') {
            this.tablesService.cancelClaimTable({
                table: {
                    connect: event.tableId
                },
                consumer: {
                    connect: this.consumerService.state.consumerId.getValue(),
                }
            }).then((res) => {
                this.fetchTables();
            });
        }
    }

    ngOnInit() {
        this.fetchTables();
    }
}
