import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor(
        private storage: Storage,
    ) {
    }

    /**
     * Store key:value pair
     *
     * @param key
     * @param value
     */
    async set(key: string, value: string | object): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.storage.set(key, value)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * Retrieve value by key
     *
     * @param key
     */
    async get(key: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.storage.get(key)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    /**
     * Retrieve multiple values by keys
     *
     * @param keys
     */
    async getKeys(keys: [string]): Promise<any> {
        return new Promise((resolve, reject) => {
            const result = [];
            this.storage.forEach((value, key, index) => {
                if (keys.indexOf(key) > -1) {
                    result[key] = value;
                }
            }).then(() => {
                resolve(result);
            });
        });
    }
    /**
     * Remove value by key
     *
     * @param key
     */
    async remove(key: string): Promise<any> {
        return this.storage.remove(key)
            .then(() => true)
            .catch((error) => {
                throw error;
            });
    }
    async clear(): Promise<void> {
        return this.storage.clear();
    }
}
