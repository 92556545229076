import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '../../../config/globals';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'app-c-app-downloads',
    templateUrl: './c-app-downloads.component.html',
    styleUrls: ['./c-app-downloads.component.scss'],
})
export class CAppDownloadsComponent implements OnInit {

    @Input() isFloating = false;

    public linkIos = '';
    public linkAndroid = '';
    public onMobile = false;

    constructor(
        private platform: Platform,
    ) {
    }

    ngOnInit() {
        this.linkAndroid = Globals.config.APP_LINKS.ANDROID;
        this.linkIos = Globals.config.APP_LINKS.IOS;
        if (this.platform.is('cordova')) {
            this.onMobile = true;
        }
    }
}
