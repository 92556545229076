import { HttpClientModule, HttpClient } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './modules/shared/shared.module';
import { GraphQLModule } from './graphql.module';
import { IonicStorageModule } from '@ionic/storage';
import { StorageService } from './services/ionic-storage.service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';

import { ConfirmationService } from 'primeng/api';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
    declarations: [
        AppComponent,
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        SharedModule,
        GraphQLModule,
        LeafletModule,
        ToastModule,
        TranslateModule.forRoot({
            defaultLanguage: 'nl',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        ConfirmationService,
        Geolocation,
        InAppBrowser,
        FirebaseX,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: StorageService},
        {provide: MessageService}
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {
}
