import { Injectable } from '@angular/core';

@Injectable()

export class Globals {
    static config = {
        LAT_NL: '52.387386',
        LON_NL: '4.646219',
        LAT_UK: '52.489471',
        LON_UK: '-1.898575',
        APP_VERSION: '1.9',
        LOCAL_VERSION: '2.0.0',
        LANGUAGES: [
            {id: 'nl', translation: 'Nederlands'},
            {id: 'en', translation: 'English'}
        ],
        PRIVACY: {
            nl: 'https://www.freetable.nl/privacy-verklaring/',
            en: 'https://www.freetable.uk/privacy-policy/'
        },
        CONDITIONS: 'https://www.freetable.nl/gebruikersvoorwaarden/',
        FAQ: {
            nl: 'https://www.freetable.nl/',
            en: 'https://www.freetable.uk/'
        },
        APP_LINKS: {
            ANDROID: 'https://play.google.com/store/apps/details?id=nl.endeavour.freetablecustomers',
            IOS: 'https://apps.apple.com/us/app/freetable/id1625101351',
        }
    };
}
