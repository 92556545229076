import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: '[appAButton]',
})
export class AButtonDirective {

    @Input() color: 'grey' | 'orange' | 'green' | 'white' | '' = '';
    @Input() isShort = false;
    @Input() isRound = false;
    @Input() isDisabled = false;
    @Input() isFullWidth = false;

    @HostBinding('class') get classes(): string {
        const classes: Array<string> = [];

        classes.push('a-button');
        if (this.color) {
            classes.push(`a-button--${this.color}`);
        }
        if (this.isShort) {
            classes.push(`a-button--isShort`);
        }
        if (this.isRound) {
            classes.push(`a-button--isRound`);
        }
        if (this.isDisabled) {
            classes.push(`a-button--isDisabled`);
        }
        if (this.isFullWidth) {
            classes.push(`a-button--isFullWidth`);
        }

        return classes.join(' ');
    }

}
