import { Component, Input, OnInit } from '@angular/core';
import { ListingService } from '../../../../services/listing.service';
import { RestaurantByIdInput, Translatable } from '../../../../generated/graphql';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../services/language.service';

@Component({
    selector: 'app-m-restaurant-info',
    templateUrl: './m-restaurant-info.component.html',
    styleUrls: ['./m-restaurant-info.component.scss'],
})
export class MRestaurantInfoComponent implements OnInit {

    @Input() title = '';
    @Input() location = '';
    @Input() distance: number;
    @Input() time = '';
    @Input() type = '';
    @Input() price = 0;
    @Input() rating = 0;
    @Input() extraInfo: Translatable;

    public priceIndication: string;

    constructor(
        public listingService: ListingService,
        public translate: TranslateService,
        public languageService: LanguageService,
    ) {

    }

    ngOnInit() {
        if (this.price) {
            this.priceIndication = this.listingService.priceIndication(this.price);
        }
    }

}
