import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Restaurant } from '../../../generated/graphql';
import { TablesService } from '../../../services/tables.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-c-history-claims',
    templateUrl: './c-history-claims.component.html',
    styleUrls: ['./c-history-claims.component.scss'],
})
export class CHistoryClaimsComponent implements OnInit {

    public establishments$: BehaviorSubject<Restaurant[]> = new BehaviorSubject([]);

    constructor(
        private tablesService: TablesService,
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.tablesService.getHistoryConsumerClaims().then((response) => {
            if (!response) {
                return;
            }
            const claimedTables = [];
            response.data.consumerClaims.data.forEach((table) => {
                claimedTables.push(table.restaurant);
            });
            if (claimedTables) {
                this.establishments$.next(claimedTables);
            }
        });
    }
}
