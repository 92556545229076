import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Restaurant, Table } from '../../../generated/graphql';
import { TablesService } from '../../../services/tables.service';
import { ConsumerService } from '../../../services/consumer.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-c-waiting-list-claims',
    templateUrl: './c-waiting-list-claims.component.html',
    styleUrls: ['./c-waiting-list-claims.component.scss'],
})
export class CWaitingListClaimsComponent implements OnInit, OnDestroy, AfterViewInit {

    public tables$: BehaviorSubject<Table[]> = new BehaviorSubject([]);
    private subscriptions$ = new Subscription();

    constructor(
        private tablesService: TablesService,
        private consumerService: ConsumerService,
        private messageService: MessageService,
        public translate: TranslateService,
        private router: Router
    ) {
    }

    public redirectTo(uri: string) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate([uri]));
    }

    public fetchTables() {
        console.log('WaitingListClaimsComponent.fetchTables');
        this.tablesService.getCurrentNotifications().then((response) => {
            if (!response) {
                return;
            }
            const claimedTables = [];
            if (response.data.consumerNotifications
                && Array.isArray(response.data.consumerNotifications)
            ) {
                response.data.consumerNotifications.forEach((table) => {
                    claimedTables.push(table);
                });
                if (claimedTables) {
                    this.tables$.next(claimedTables);
                }
                console.log('CWaitingListClaimsComponent claimedTables', claimedTables);
                this.subscriptions$.add(this.tablesService.restaurantIdFromPush.subscribe(restaurantId => {
                    if (restaurantId !== null) {
                        console.log(`CWaitingListClaimsComponent received restaurantId ${restaurantId}`);
                        const tables = this.tables$.getValue();
                        const table = tables.find((t) => t.restaurant.id === restaurantId);
                        if (table) {
                            table.status = 'claimable';
                            this.tables$.next(tables);
                        }
                    }
                }));
            }
        });
    }

    public handleClaims(event) {
        console.log(event);
        if (event.status === 'waiting_list') {
            this.tablesService.deleteNotification(event.tableId).then(() => {
                this.fetchTables();
            });
        } else {
            // when we received a restaurantId from push
            this.tablesService.claimTable(event).then((claimTableResponse) => {
                this.tablesService.deleteNotification(event.tableId).then(() => {
                    this.fetchTables();
                });
                if (claimTableResponse.errors) {
                    this.messageService.add({
                        severity: 'error',
                        summary: this.translate.instant('errors.error'),
                        detail: this.translate.instant('errors.no-longer-claimable')
                    });
                } else {
                    this.redirectTo('/my-tables');
                }
            }).catch((err) => {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translate.instant('errors.error'),
                    detail: err
                });
            });
        }
    }

    public ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    public ngOnInit() {
        this.fetchTables();
    }

    public ngAfterViewInit() {
        console.log('WaitingListClaimsComponent ngAfterViewInit()');
    }
}
